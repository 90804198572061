@import url('https://fonts.googleapis.com/css?family=Barlow+Condensed&display=swap');
@import '_variables_mixins.scss';

.side-bar .drawer-panel.units-list-container {
    background : fade-out($mainColor, 0.1);
    overflow : auto;
    z-index : 4;
    .units-filter {
        margin : 20px 20px 0;
        min-width : 310px;
    }
    .units-list {
        display : flex;
        flex-wrap : wrap;
        justify-content: center;
        padding: 10px;
        margin: 10px;
        min-width : 330px;
        .country-wrap {
            display : flex;
            flex-wrap : wrap;
            width : 100%;
            .header {
                margin : 3px 0;
            }
        }
        .unit-wrap {
            display : flex;
            flex-direction : column;
            margin: 3px 0;
            width : 100%;
            .unit-toggle {
                align-self : flex-end;
                margin : -2px 0 0 0;
                padding : 3px 8px 4px;
                background : $mainColor;
                font-size : 12px;
                border-bottom-left-radius : 3px;
                border-bottom-right-radius : 3px;
                opacity : 1;
            }

        }
        li {
            position : relative;
            display : flex;
            align-items : center;
            justify-content : space-between;
            flex-grow : 1;
            color: #fff;
            list-style: none;
            //width: 100%;
            padding: 10px 8px;
            border-radius : 4px;
            background: darken($mainColor, 5%);
            //font-family : $secondaryFont;
            font-family : $mainFont;
            font-size : 15px;
            line-height : 1.2;
            letter-spacing : 0.5px;
            text-shadow : 1px 1px #000;
            //margin: 3px;
            transition: 200ms;
            cursor: pointer;
            text-align : left;
            span {
                display : flex;
                align-items : center;
                padding-right : 12px;
            }
            &:hover {
                background: darken($mainColor, 15%);
            }
            // unit size hierarchy
            // Army -> Army Group -> Division -> Corps -> Brigade -> Battalion -> Company -> Regiment -> Platoon
            &.army {}

            &.army-group {
                margin-left: $unitHierarchyGap;
            }

            &.division {
                margin-left: $unitHierarchyGap * 1.5;
            }

            &.crops {
                margin-left: $unitHierarchyGap * 2;
            }

            &.brigade {
                margin-left: $unitHierarchyGap * 2.5;
            }

            &.battalion {
                margin-left: $unitHierarchyGap * 3;
            }

            &.company {
                margin-left: $unitHierarchyGap * 3.5;
            }

            &.regiment {
                margin-left: $unitHierarchyGap * 4;
            }

            &.platoon {
                margin-left: $unitHierarchyGap * 4.5;
            }

            &.header {
                padding: 14px 10px;
                font-size : 18px;
                justify-content : space-between;
                border: 1px solid #2e3b41;

                &.can {
                    background: darken($canColor, 5%);
                    &:hover {
                        background: darken($canColor, 15%);
                    }
                }

                &.gb {
                    background: darken($gbColor, 5%);
                    &:hover {
                        background: darken($gbColor, 15%);
                    }
                }

                &.nz {
                    background: darken($nzColor, 5%);
                    &:hover {
                        background: darken($nzColor, 15%);
                    }
                }

                &.sa {
                    background: darken($saColor, 5%);
                    &:hover {
                        background: darken($saColor, 15%);
                    }
                }

                &.fre {
                    background: darken($freColor, 5%);
                    &:hover {
                        background: darken($freColor, 15%);
                    }
                }

                &.pol {
                    background: darken($polColor, 5%);
                    &:hover {
                        background: darken($polColor, 15%);
                    }
                }

                &.usa {
                    background: darken($usaColor, 5%);
                    &:hover {
                        background: darken($usaColor, 15%);
                    }
                }

                &.bra {
                    background: darken($braColor, 5%);
                    &:hover {
                        background: darken($braColor, 15%);
                    }
                }

                &.gmn {
                    background: darken($gmnColor, 5%);
                    &:hover {
                        background: darken($gmnColor, 15%);
                    }
                }

                &.ita {
                    background: darken($itaColor, 5%);
                    &:hover {
                        background: darken($itaColor, 15%);
                    }
                }

                &.ind {
                    background: darken($indColor, 5%);
                    &:hover {
                        background: darken($indColor, 15%);
                    }
                }

                &.jpn {
                    background: darken($jpnColor, 5%);
                    &:hover {
                        background: darken($jpnColor, 15%);
                    }
                }

                &.cze {
                    background: darken($czeColor, 5%);
                    &:hover {
                        background: darken($czeColor, 15%);
                    }
                }

                i {
                    font-size : 16px;
                }
            }
            &.unit {
                border-left : 2px solid;
                .fa-book-open {
                    //position : absolute;
                    //top : -3px;
                    //top : 50%;
                    //transform : translateY(-50%);
                    //right : -6px;
                    font-size : 18px;
                    opacity : 0.8;
                }
                &.gmn {
                    border-color: $gmnColor;
                }

                &.gb {
                    border-color: $gbColor;
                }

                &.can {
                    border-color: $canColor;
                }

                &.usa {
                    border-color: $usaColor;
                }

                &.pol {
                    border-color: $polColor;
                }

                &.fre {
                    border-color: $freColor;
                }

                &.ita {
                    border-color: $itaColor;
                }

                &.nz {
                    border-color : $nzColor;
                }

                &.bra {
                    border-color : $braColor;
                }
                &.ind {
                    border-color : $indColor;
                }

                &.sa {
                    border-color : $saColor;
                }
            }



            // hovering opacity classes
            // &.hover-none {}

            // &.hover-active {}

            // &.hover-inactive {}

            .unit-icon {
                width : 24px;
                min-width : 24px;
                color : #fff;
                opacity : 0.2;
                font-size : 22px;

                font-family: simple;
                margin-right: 10px;
                //color: #000;
                //background-color: #fff;
                //padding: 5px;
                text-align: center;
                height: fit-content;
                width: fit-content;

                &.gmn {
                    // font-family: german;
                    //color: #342d2d;
                }

                /*
                &.gb {
                    color: #cf142b;
                }

                &.can {
                    color: #ff0000;
                }

                &.usa {
                    color: #3c3b6e;
                }

                &.pol {
                    color: #c36b13;
                }

                &.fre {
                    color: #0055a4;
                }
                */
            }

            &.disabled.unit {
                display: none;
            }

            i {
                margin : 0;
                font-size : 20px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .side-bar .units-list-mobile-container {
        position : absolute;
        .drawer-panel.active {
            max-width : 0px;
            background : $mainColor;
            border-top : 1px solid darken($mainColor, 5%);
        }
        &.active {
            .drawer-panel.active {
                max-width : 450px;
            }
        }
        /*
        position : absolute;
        top : 0px;
        bottom : 0px;
        right : 0px;
        //padding : 30px;
        max-width : 0px;
        overflow : auto;
        transition : 600ms;
        &.active {
           max-width : 400px;
           width : 450px;
           transition : 600ms;
        }
        */
    }
}
