$mainColor : #2e3b41;
$accentRed : #841105;
$mainFont : 'Barlow Condensed', sans-serif;
$secondaryFont : Arial;

$gbColor: #8b7641;
$canColor: #aa1d1d;
$gmnColor: #191515;
$usaColor: #1e338f;
$polColor: #eb880f;
$freColor: #3687d3;
$itaColor: #36a645;
$jpnColor: #fc7f03;
// temp colors that makes it easier to visually distinguish
$braColor: $usaColor;
$nzColor: $gbColor;
$saColor: $gbColor;
$indColor: $gbColor;
$czeColor: $gbColor;
// $nzColor: $mainColor;
// $saColor: $mainColor;
// $braColor: $mainColor;
// $itaColor: $mainColor;
// $indColor: $mainColor;

$mobileSliderHeight : 144px;
$mobileHeaderHeight : 44px;

$unitHierarchyGap: 12px;

@mixin close-button-mixin {
    font-size : 20px;
    border : 1px solid #ccc;
    border-radius : 50%;
    height : 35px;
    width : 35px;
    display : flex;
    align-items : center;
    justify-content : center;
    cursor : pointer;
    transition : 150ms;
    opacity : 1;
    &:hover { opacity : 0.6; }
}
