body {
  background: rgb(247, 244, 240);
}

.login {
  height: 100vh;
  width: 100vw;
  background: rgb(247, 244, 240);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.dashboard-container {
  min-height: 100vh;
}

.dashboard-nav {
  margin: 10px;
  border-radius: 4px;
  color: #fff;
  font-family: "Lato";
  background-color: #1b1c1d !important;
  padding: 0 5px 0 0;
}

@media screen and (min-width: 720px) {
  /* .dashboard-nav {
    max-width: 90vw;
  } */
}
@media screen and (min-width: 900px) {
  /* .dashboard-nav {
    max-width: 80vw;
  } */
}
@media screen and (min-width: 1200px) {
  /* .dashboard-nav {
    max-width: 66.66vw;
  } */
}

.dashboard-nav .navbar-brand {
  font-family: "Special Elite", cursive;
  font-size: 26px;
  background: #333;
  padding: 9px 12px 1px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.dashboard-nav .nav-item {
  margin: 4px 8px;
  font-size: 16px;
  white-space: nowrap;
  cursor: pointer;
}

.dashboard-nav .nav-item.active {
  border-bottom: 3px solid #00b5ad;
}
.dashboard-nav .nav-item i {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
  font-size: 30px;
  border-radius: 50%;
  background: #333;
  color: #fff;
}

.dashboard-nav a {
  color: #fff;
}
.dashboard-nav a:hover {
  text-decoration: none;
}

.features-container {
  /* width : 94vw; */
  width: 100%;
  margin: 30px auto;
}
.dashboard-date {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.dashboard-date i {
  margin: 0px 15px;
  font-size: 14px;
  color: #ccc;
  cursor: pointer;
  transition: 200ms;
  border: 1px solid;
  padding: 6px;
  border-radius: 4px;
}
.dashboard-date i:hover {
  color: #333;
}
.dashboard-nav .nav-item .ui.large.basic.label {
  margin-top: 10px;
  font-family: lato;
}
.feature-table {
  margin: 20px 0;
  font-family: lato;
  min-width: 100%;
  float: left;
  background-color: #ffffff;
}

.map-edit {
  /* background : #ddd; */
  padding-bottom: 10px;
  float: left;
  width: 100%;
}
.map-edit .ui.segment.edit-pane {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  max-width: 90vw;
  min-height: 500px;
}
.map-edit .edit-pane h2 {
  width: 100%;
  text-align: center;
}
.map-edit .edit-pane .props {
  padding-right: 20px;
  width: 40%;
  min-width: 400px;
}
.map-edit .edit-pane .map-wrap {
  flex-grow: 1;
  margin: 24px 20px;
}
.map-edit .edit-pane #map {
  min-height: 400px;
  width: 100%;
  position: relative;
  margin-top: 10px;
}
.buttonWrap {
  width: 100%;
  margin: 10px 0px;
  text-align: center;
}

.hide-section {
  display: none;
}

.search-wrap .date-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.search-wrap .date-wrap .date-label-text {
  font-size: 14px;
  margin: 0;
}
.search-wrap .date-wrap .date-text {
  font-size: 20px;
}
.filter-wrap {
  display: flex;
  margin-left: 20px;
}
.filter-wrap .single-filter {
  width: 150px;
  margin-right: 10px;
}
.filter-wrap .select-label {
  margin: 0;
}

h5.custom-data-error {
  font-size: 1rem;
  line-height: 2rem;
  background-color: #cc5959;
  padding: 5px 10px;
  margin-left: 10px;
  border-radius: 6px;
  color: white;
  font-weight: 700;
}

@media screen and (max-width: 900px) {
  .map-edit .ui.segment.edit-pane {
    flex-direction: column;
  }
}
