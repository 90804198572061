@import url('https://fonts.googleapis.com/css?family=Barlow+Condensed&display=swap');
@import '_variables_mixins.scss';

@font-face {
  font-family: 'allied';
  src: local('P44AlliedMarkers'), url(../fonts/P44AlliedMarkers.ttf) format('truetype');
}
@font-face {
  font-family: 'simple';
  src: local('P44Silheouttes'), url(../fonts/P44Silheouttes.ttf) format('truetype');
}
@font-face {
  font-family: 'german';
  src: local('P44GermanMarkers'), url(../fonts/P44GermanMarkers.ttf) format('truetype');
}
@font-face {
  font-family: 'nato';
  src: local('P44NatodMarkers'), url(../fonts/P44NatoMarkers.ttf) format('truetype');
}

/* main app framework */
.capitalize {
  text-transform: capitalize;
}
.uppercase {
  text-transform: uppercase;
}

// Hide tiny editor warning message
.tox .tox-notification--warning {
    display : none !important;
}

.ui.segment.tutorial-prompt, .ui.segment.donation-prompt {
    position : absolute;
    max-width : 250px;
    bottom : 0px;
    right : 60px;
    z-index : 10;
    padding : 15px;
    font-size : 18px;
    font-family : $mainFont;
    line-height : 30px;
    div {
        width : 100%;
        text-align : center;
        .ui.button {
            margin-top : 10px;
            font-family : $mainFont;
            font-size : 18px;
            padding : 12px 16px;
        }
    }
    .fa-question-circle {
        //color : #000;
        position : relative;
        top : 0;
        right : 0;
        font-size : 18px;
        pointer-events : none;
    }
}
.fa-question-circle {
    position : absolute;
    right: 15px;
    bottom: 75px;
    z-index: 20;
    //color: #F3E67E;
    //color : #9EC263;
    //color : #C27F68;
    color : #688fc2;
    font-size: 22px;
    cursor: pointer;
    transition : 200ms;
    &:hover { opacity : 0.6 }
}

.ui.segment.donation-prompt {
    top : 80px;
    left : -350px;
    max-width : 320px;
    bottom : auto;
    right : auto;
    font-family : $mainFont;
    transition : 500ms;
    &.active {
        left : 10px;
    }
    h1 {
        font-size : 30px;
        font-family : $mainFont;
        text-align : center;
        a {
            text-decoration : none;
        }
    }
    p {
        font-size : 17px;
        a {
            font-size : inherit;
        }
    }
    a {
        color : #b60f0c;
        transition : 150ms;
        text-decoration : underline;
        &:hover {
            color : lighten(#b60f0c, 15%);
        }
    }
}

/* Zoom & Geolocate offset from sidebar */

.mapboxgl-ctrl-top-right, .mapboxgl-ctrl-bottom-right {
    right : 60px;
}

.mapboxgl-ctrl-top-right {
    display: flex;
    flex-direction: row-reverse;
    align-items : center;
    right : 400px; 
    @media screen and (max-width : 768px) {
        top : 110px;
        right : 0; 
    }
    @media screen and (max-width : 500px) {
        .mapboxgl-ctrl-geocoder {
            min-width : 200px;
            max-width : 200px;
        }
    }
    @media screen and (max-width : 768px) {
        .mapboxgl-ctrl-geocoder {
            .mapboxgl-ctrl-geocoder--input {
                height : 36px;
                padding : 6px 34px;
                &::placeholder {
                    font-size : 14px;
                }
            }
            .mapboxgl-ctrl-geocoder--icon {
                top : 9px;
                left : 6px;
                width : 20px;
                height : 20px;
            }
        }
    }
}

/* Make sure  mapbox attribution isn't hidden */
.mapboxgl-ctrl-bottom-left {
    bottom : 40px;
}

/* popups */
.no-container .mapboxgl-popup-content {
  padding: 0;
  background: none;
  border: none;
  box-shadow: none;
  max-width: 50px;
  pointer-events: none;
  text-align: center;
}
.no-container .mapboxgl-popup-content img {
  width: 30px;
  height: 30px;
  margin: 0 auto;
}
.no-container .text {
  text-align: center;
  text-shadow: white 2px 2px 5px;
  background: rgba(255,255,255,0.9);
  font-size: 9px;
  padding: 2px;
  line-height: 10px;
}
.icon-image.larger {
  width: 33px !important;
  height: 33px !important;
}
.poi-icon {
  max-width: 33px;
  max-height: 33px;
}
.poi-popup-details {
  width: 250px;
}
.poi-row {
  margin: 5px 10px;
}
.poi-row input {
  width: 200px;
}
.photo-modal {
    font-family : $mainFont;
    font-size : 20px;
    max-width : 700px;
    .close {
        font-size : 40px;
    }
    .modal-title {
        font-family : $mainFont;
        font-size : 30px;
    }
    .description {
        text-align : center;
        margin : 20px;
    }
    .slider-img {
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-color : $mainColor;
        border-radius : 2px;
    }
    a {
        word-break : break-all;
    }
    iframe {
        max-width : 80vw;
    }
}
/* maps */
#map {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  /* z-index: -10; */
  outline: none;
}
.background-map {
  position: absolute;
  top:0;
  bottom:0;
  width: 100%;
  z-index:-10;
  overflow: hidden;
}
.background-map img {
  width: 100%;
}
/* sidebar */
.sidebar-area {
  position: absolute;
  right: 0;
  top:0;
  bottom:0;
  padding:0px 10px;
  width: 250px;
  margin-right:-250px;
  z-index: -5;
  background: #555;
  transition: 0.5s;
}
.sidebar-area h3 {
  margin-top: 70px;
  color: #eee;
}

.sidebar-area.open {
  margin-right:0px;
}

.sidebar-area .toggles {

}

.sidebar-area .navbar-toggler {
  transform: rotate(90deg);
  position: absolute;
  background: #555;
  left: 0;
  margin-left:-50px;
}

/* bottom */
.bottom-area {
  position: absolute;
  width: 100%;
  bottom:0;
  text-align: center;
  background: #555;
  height: 270px;
  margin-bottom:-250px;
  transition: 0.5s;
}
.bottom-area.open {
  margin-bottom:0px;
}
.bottom-area .map-list {

}

.bottom-area .map-list img {
  width: 23%;
  margin: 0px 10px;
  display: inline-block;
}

.bottom-area .navbar-toggler {
  background: #555;
  margin-top:-20px;
}

.modal-close {
    right: 1rem;
    position: absolute;
}

/* Header */
.main-nav {
    .navbar-brand {
        background-image : url('/img/PROJECT-44-Favicon-POS_Patreon.png');
        height: 100px;
        width: 110px;
        position: absolute;
        top: -5px;
        left: 0;
        background-size: cover;
    }
    a {
        color: #fff;
        &:hover {
            text-decoration: none;
        }
    }
    &.bg-dark {
        font-family: $mainFont;
        z-index : 12;
        position: absolute;
        border-bottom-right-radius : 4px;
        color: #fff;
        //background-color: #1b1c1d !important;
        background-color: $mainColor !important;
        padding: 0 5px 0 110px;
        box-shadow : 1px 1px 1px -1px darken($mainColor, 15%);
    }
    .nav-item {
        margin : 8px 8px;
        font-size: 20px;
        white-space: nowrap;
        cursor: pointer;
        text-transform : uppercase;
        transition : 200ms;
        &:hover {
            color : lighten($mainColor, 30%);
            a {
                color : lighten($mainColor, 30%);
            }
        }
        .nav-link {
            color: white !important;
            padding: 0px;
        }
        a {
          color: white;
        }
    }
}

.filtered {
    opacity: 0.4;
}

/* Loading panel */
#loading-container {
  z-index: 100;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 2em;
  font-weight: bolder;
  min-width: 240px;
  text-align: center;
  background: #fff;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 0px 0px 5px -1px #aaa;
  span {
    font-family: $mainFont;
    font-size: 26px;
    margin-bottom: 10px;
    display: inline-block;
  }
  .month-status {
    display: flex;
    flex-wrap: wrap;
    div {
        opacity: 0.2;
        transition: 100ms;
        font-size: 16px;
        width: 50%;
        font-weight: normal;
        text-transform: uppercase;
        margin-bottom: 18px;
        font-family: $mainFont;
        &.loaded {
            opacity : 1;
            .fa-check-circle {
                color : #66da81;
            }
        }
        .fa-check-circle {
            margin-top: 5px;
            display: block;
            font-size: 22px;
        }
    }
  }
}

/* Side bar */
$sideBarWidth : 50px;
.side-bar {
    position : absolute;
    z-index : 3;
    top : 0px;
    right : 0px;
    bottom : 0px;
    background : $mainColor;
    display : flex;
    flex-direction : column;
    padding : 10px;
    align-items: center;
    width : $sideBarWidth;
    box-shadow : 0px 0px 4px -1px darken($mainColor, 15%);
    .icon-wrap {
        margin : 17px 0px;
        i {
            margin : 0;
        }
        .icon-label {
            display : none;
        }
    }
    i {
        color : #fff;
        font-size : 26px;
        margin : 17px 0px;
        opacity : 0.6;
        //text-shadow: 1px 1px #000;
        cursor : pointer;
        transition : 200ms;
        &:last-of-type {
            margin-top : auto;
        }
        &.active {
            opacity : 1;
            transform : scale(1.2);
        }
        &:hover {
            //opacity : 1;
        }
    }
    .story-map-link {
        width : 40px;
        height : 40px;
        margin-bottom : 20px;
        .story-map-icon {
            display : inline-block;
            width : 40px;
            height : 40px;
            background-image : url('/img/story-map-icon.png');
            background-size : cover;
            filter : brightness(0) invert(1);
            margin-top : 10px;
            cursor : pointer;
        }
    }
    .drawer-panel {
        position : absolute;
        top : 0px;
        bottom : 0px;
        right : 50px;
        //padding : 30px;
        max-width : 0px;
        overflow : hidden;
        transition : 600ms;
        z-index : 6;
        &.active {
           max-width : 400px;
           width : 350px;
           transition : 600ms;
        }
        .layersContainer, .settingsContainer, .iconsContainer {
            .ui.segment.map-settings {
                background: transparent !important;
                height: auto !important;
            }
        }

        .ui.segment.filters-box, .ui.segment.map-settings {
            padding : 0px;
            height : 100vh;
            min-width : 340px;
            z-index: 3;
            transition: right 1s;
            background : $mainColor;
            overflow : auto;
            border-radius : 0px;
            font-family : $secondaryFont;
            .ui.header {
              color: #fff;
              margin: 10px 0px;
              padding : 5px 0px;
              font-family : $mainFont;
              font-size : 20px;
              text-align: center;
              /*
              border-top : 2px solid #000;
              border-bottom : 2px solid #000;
              */
              background : darken($mainColor, 10%);
              text-transform : capitalize;
              letter-spacing : 1px;
              i.fa-times {
                @include close-button-mixin;
                position : absolute;
                top : 12px;
                left : 13px;
                font-size : 12px;
                height : 25px;
                width : 25px;
                opacity : 0.8;
              }
              &.accordion-title {
                margin : 0px;
                padding : 10px 0px;
                border : none;
                background : darken($mainColor, 10%);
                margin-bottom : 15px;
                font-size : 24px;
              }
            }
            .item {
                .ui.header i.fa-angle-double-right {
                    float : left;
                    margin : 0px -10px 0px 8px;
                }
                .accordion {
                    max-height : 0px;
                    overflow : hidden;
                    transition : 400ms;
                    &.active {
                        max-height : 400px;
                        overflow : auto;
                    }
                    .icon-type {
                        label {
                            width : 110px;
                            display : flex;
                            justify-content : space-between;
                            span {
                                font-size : 26px;
                                opacity : 0.6;
                            }
                        }
                    }
                }
            }
            .ui.toggle.checkbox {
                margin-left : 5px;
            }
            .ui.toggle.checkbox input~label, .ui.toggle.checkbox input:checked~label {
              color: #fff !important;
              font-family : $secondaryFont;
              letter-spacing : 0.6px;
              font-size : 13px;
            }
            .ui.slider.checkbox {
                display : block;
                margin-top : 20px;
            }
            label {
                color: rgba(255,255,255,0.9);;
                margin-left : 12px;
            }
            .ui.radio.checkbox {
                width: 100%;
                margin-top: 12px;
            }
            .ui.radio.checkbox input~label {
                color : #fff;
            }
        }
        .basemap-options {
            padding-left : 10px;
            letter-spacing : 0.6px;
            font-size : 13px;
            > div {
                cursor : pointer;
                &:hover {
                    label { opacity : 1; }
                    img {  transform : scale(1.05); }
                }
                &.active {
                    label { opacity : 1; }
                    img {
                        border-color : #9e1907;
                        box-shadow: 0px 0px 0px 1px #902d2d;
                        transform : scale(1.1);
                    }
                }
                label {
                    opacity : 0.7;
                    cursor : pointer;
                    transition : 150ms;
                    color : #fff;
                }
                img {
                    width : 60px;
                    height : 40px;
                    margin : 5px;
                    border : 1px solid darken($mainColor, 15%);
                    border-radius : 2px;
                    cursor : pointer;
                    transition : 200ms;
                }
            }
        }
    }
    .info-text {
      position: absolute;
      right : $sideBarWidth + 10px;
      bottom : 50px;
      background: rgba(255,255,255,0.8);
      padding: 5px 10px;
      border-radius: 10px;
      min-width: 200px;
      box-shadow: 2px 2px 2px #ccc;
    }
    .tools-placeholders {
        position : absolute;
        display : flex;
        right : $sideBarWidth + 10px;
        bottom : 10px;
        i {
            display : flex;
            align-items : center;
            justify-content : center;
            color : #fff;
            background : $mainColor;
            padding : 10px;
            width : 43px;
            height : 43px;
            border-radius : 50px;
            opacity : 1;
            margin : 4px;
            box-shadow : 1px 1px 2px 1px darken($mainColor, 15%);
            &:hover {
                background : darken($mainColor, 20%);
            }
            &.active {
                background : darken($mainColor, 20%);
                transform : scale(1.1);
            }
        }
    }
}

.magnifying-glass-tool {
    position : fixed;
    background-color : #fff;
    background-repeat : no-repeat;
    z-index : 1;
    height : 200px;
    width : 200px;
    border : 1px solid $mainColor;
    box-shadow : 0px 0px 2px 1px;
    border-radius : 50%;
    pointer-events : none;
    cursor : none;
}

/* Edit Units section */
.ui.segment.edit-units-section {
    font-family: $mainFont;
    background: $mainColor;
    z-index: 10;
    position: absolute;
    bottom: 0px;
    left: 0px;
    margin: 0px;
    border-radius: 0px;
    border-top-right-radius: 3px;
    box-shadow: -1px -1px 2px -1px darken($mainColor, 15%);
    min-height : 200px;
    &.hidden {
        max-height : 0px;
        min-height : 0px;
        padding : 0px;
        overflow : hidden;
    }

    .edit-units-header {
        display : flex;
        justify-content : space-between;
        .select-wrap {
            flex-grow : 2;
            color : #000;
            font-size : 16px;
            margin-right : 10px;
            max-width : 350px;
        }
        .play-buttons {
            overflow : hidden;
            flex-grow : 1;
            display : flex;
            justify-content : space-between;
            align-items : center;
            margin-right : 10px;
            max-width : 135px;
            > div { padding : 5px 10px; }
            .date {
                font-size : 20px;
                pointer-events : none;
                width : auto;
            }
            i { font-size : 16px }
        }
        i.fa-times {
            @include close-button-mixin;
            font-size : 13px;
            height : 25px;
            width : 25px;
            margin-top : 4px;
        }
    }

    .ui.button {
        font-family: $mainFont;
        font-size: 13px;
        letter-spacing: 0.4px;
        &.add-button {
            padding : 14px;
            font-size : 16px;
            font-weight: normal
        }
    }

    .edit-units-table {
        max-width: 1000px;
        margin-bottom : 2px;
        margin-top : 0px;
        transition : 300ms;

        font-family : arial;
        font-size : 13px;
        &.hidden {
            display: none;
        }
        &.inactive {
            pointer-events : none;
            opacity : 0.3;
        }
    }

    .ui.input {
        max-width: 100px;
    }

    table.ui.celled tbody tr td {
        padding: 5px;
        pointer-events: all !important;
        cursor : pointer;
        vertical-align : middle;
    }
    table.ui.celled tbody tr.edit-row {
        background : #e6eafb;
        td {
            border-bottom : 1px solid rgba(34,36,38,.1);
        }
    }
    .ui.pagination.menu .item {
        font-family : $mainFont;
    }
}

/* Poi Window */
.ui.segment.poi-window, .ui.segment.feature-edit-window {
    font-family: $secondaryFont;
    background: $mainColor;
    z-index: 10;
    position: absolute;
    top: 100px;
    left: 10px;
    margin: 0px;
    border-radius: 3px;
    border-top-right-radius: 3px;
    box-shadow: 0px 1px 2px -1px darken($mainColor, 15%);
    z-index : 100;
    padding : 20px 10px 12px;

    .ui.form {
        .field {
            position : relative;
            > label {
                position : absolute;
                top : -8px;
                background : $mainColor;
                color : rgba(#fff, 0.5);
                font-weight : normal;
                padding : 0px 5px;
                left : 10px;
                z-index : 1;
                letter-spacing : 0.6px;
            }
        }
    }

    .ui.input input, .ui.form textarea,
    .ui.form .color-picker,
    .ui.form .opacity-picker,
    .ui.form .marker-options {
        background: #2f3b41;
        border-width: 2px;
        color: white;
        padding : 16px;
        font-family : $secondaryFont;
    }

    .ui.form .opacity-picker {
        border : 2px solid rgba(255,255,255,0.1);
        border-radius : 4px;
        .rc-slider {
            margin-bottom : 5px;
            margin-top : 5px;
            max-width : 100px;
        }
    }

    .ui.form .marker-options {
        border : 2px solid rgba(255,255,255,0.1);
        border-radius : 4px;
        max-width : 300px;
        display : flex;
        flex-wrap : wrap;
        i {
            font-size : 22px;
            margin : 10px;
            cursor : pointer;
            transition : 300ms;
            &:hover {
                transform : scale(1.2);
            }
        }
    }

    .ui.form textarea {
        border-color: rgba(255,255,255,.1);
        height : 100px !important;
    }

    .ui.form .color-picker {
        border : 2px solid rgba(255,255,255,0.1);
        border-radius : 4px;
        .swatches {
            display : flex;
            flex-wrap : wrap;
            max-width : 250px;
            .swatch {
                width : 20px;
                height : 20px;
                border-radius : 2px;
                margin : 3px;
                display : inline-block;
                cursor : pointer;
                transition : 300ms;
                display : flex;
                justify-content : center;
                align-items : center;
                &:hover {
                    transform : scale(1.2);
                }
            }
        }
    }

    .poi-icon {
        position: absolute;
        right: -8px;
        top: -7px;
        cursor: pointer;
        z-index: 11;
        i {
            @include close-button-mixin;
            height : 25px;
            width : 25px;
            font-size : 14px;
            background : $mainColor;
            &:hover {
                opacity : 1;
                background : darken($mainColor, 15%);
            }
        }
    }
    .button-wrap {
        display : flex;
        justify-content : center;
    }
    .ui.button {
        font-family : $mainFont;
        font-size : 17px;
        font-weight : normal;
        margin-right : 6px;
        background : darken($mainColor, 10%);
        padding : 10px 14px;
        color : #fff;
        &:hover {
            background : darken($mainColor, 15%);
        }
    }
    .file-upload-field {
        display : flex;
        align-items : center;
        .field {
            flex-grow : 1;
            align-items : center;
            margin-bottom : 0px;
        }
    }
}

/* Time Slider */
.ui.segment.time-slider-section {
  font-family: $mainFont;
  background : fade-out($mainColor, 0.05);
  z-index: 10;
  position: absolute;
  bottom: 5px;
  width: 350px;
  left: 50%;
  transform : translateX(-50%);
  margin : 0px;
  border-radius : 0px;
  border-radius : 3px;
  font-size : 20px;
  box-shadow : -1px -1px 2px -1px darken($mainColor, 15%);
  padding : 0px;
  .ui.dropdown.campaign-dropdown {
    width : 100%;
    background: #232d32;
    margin-bottom: 10px;
    padding: 4px;
    text-align: center;
    font-size: 16px;
    text-transform: capitalize;
    .dropdown.icon {
        margin-left : 0.5em;
    }
    .menu {
        width : 100%;
        .item {
            text-align : center;
            .text {
                font-size : 16px;
                text-transform : capitalize;
            }
        }
    }
  }
    .main-date-input {
        display : flex;
        justify-content : center;
        width : 100%;
        .icon {
            color: #eee;
            opacity: inherit;
        }

        .ui.icon.input {
            cursor: pointer;
        }

        .calendar.icon {
            max-width : 30px;
            font-size : 18px;
            opacity : 0.5;
            cursor: inherit;
        }

        input {
            padding : 0 !important;
            max-width: 128px;
            background: none;
            color: #eee;
            border: none;
            font-family: "Barlow Condensed", sans-serif;
            font-size: 24px;
            cursor: inherit;
        }
    }

  .slider-container {
       //margin : 10px 12px 0px;
      .slider-wrap {
        position : relative;
        margin : 5px 10px;
        .marker-wrap {
            display : none;

            position : absolute;
            bottom : 12px;
            background : transparent;
            color : #fff;
            font-size : 16px;
            flex-direction : column-reverse;
            opacity : 0.5;
            &.max {
                right : 10px;
                align-items : flex-end;
            }
        }
      }
  }
  .play-buttons {
    margin-bottom : 0px;
    background : transparent;
    font-size : 16px;
    color : #000;
    display : flex;
    justify-content : center;
    > div {
        padding : 2px 0px 8px;
        font-size : 12px;
        transition : 200ms;
        width: auto;
        background: black;
        opacity: 0.5;
        color: #fff;
        padding: 3px 10px;
        border-radius: 4px;
        margin: 0px 3px 6px;
        &:hover {
            opacity : 0.8;
        }
        &.play-loop-toggle {
            &.active {
                opacity : 0.8;
            }
        }
    }
  }

  @media screen and (min-width: 620px) {
        width: 400px;
        .slider-container {

            .slider-wrap .marker-wrap {
                bottom: -29px;
                opacity: 0.8;
                display : flex;

                .ts-date  {

                    .icon {
                        width : auto;
                        max-width : 70px;
                        color: #eee;
                        opacity: inherit;
                    }

                    input {
                        padding : 0;
                        max-width: 200px;
                        background: none;
                        color: #eee;
                        border: none;
                        font-family: "Barlow Condensed", sans-serif;
                        cursor : pointer;
                    }
                }
            }
        }

    }
}
.play-buttons {
  display: flex;
  margin-bottom: 10px;
  flex-direction: row;
  background: darken($mainColor, 10%);
  border-radius : 3px;
  > div {
      width: 25%;
      padding-top:10px;
      padding-bottom:10px;
      text-align: center;
      &:hover {
          cursor: pointer;
          background: #1b1c1d;
      }
  }
}

/* Users table column style */
.check.icon {
    color : #2d8e3f;
}
.center-text {
    text-align : center;
}
.capitalize-text {
    text-transform : capitalize;
}

/* POIs list table */
.pois-container {
    .ReactTable .rt-td {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .poi-edit-buttons {
        display : flex;
        flex-wrap : wrap;
        button {
            width : 48%;
            margin : 1%;
        }
    }
}

/* Custom data table */
img.custom-data-icon {
  width: auto;
  height: 50px;
}

.line-distance-container {
    position : absolute;
    top : 150px;
    border-radius : 2px;
    left : 10px;
    z-index : 10;
    color : #fff;
    box-shadow : 1px 1px 2px -1px darken($mainColor, 15%);
    background : $mainColor;
    font-family : $secondaryFont;
    min-width : 250px;
    .header-icons {
        padding : 10px;
        border-bottom : 1px solid;
        button {
            background : transparent;
            border : 1px solid;
            border-radius : 50px;
            color : #fff;
            padding : 8px 10px;
            display : flex;
            align-items : center;
            letter-spacing : 0.4px;
            &:focus { outline : none; }
            &:hover { opacity : 0.6; }
            i {
                color : #6EA7E7;
                font-size : 16px;
                margin-right : 6px;
            }
        }
        .fa-times{
            @include close-button-mixin;
            float : right;
        }
    }
    span, label {
        display : block;
        margin-left : 15px;
        margin-bottom : 15px;
        font-size : 22px;
    }
    label {
        font-size : 16px;
        margin-top : 15px;
    }
}

.blogs-modal {
    font-family : $mainFont;
    width : 92vw;
    max-width : 1050px;
    .modal-content {
        min-height : 80vh;
    }
    .blogs-custom-header {
        width : 100%;
        text-align : center;
        font-size : 38px;
        position : relative;
        padding : 10px 0px;
        margin-bottom : 10px;
        background : $mainColor;
        color : #fff;
        .fa-times {
            @include close-button-mixin;
            position : absolute;
            top : 20px;
            right : 20px;
            font-size : 18px;
            cursor : pointer;
            transition : 150ms;
            color : #fff;
            &:hover {
                opacity : 0.7;
            }
        }
    }
    .modal-body {
        .featured-carousel {
            max-width : 1000px;
            margin : 0px auto 40px;
            border-radius : 4px;
            .slider-container {
                border-radius : 4px;
            }
            .blog-item {
                height : 100%;
                border-radius : 0px;
                .featured-title {
                    background: darken($mainColor, 6%);
                    color: #fff;
                    font-size: 21px;
                    font-family: "Barlow Condensed", sans-serif;
                    text-align: center;
                    padding: 10px 14px;
                    margin-bottom : 0px;
                    text-transform: uppercase;
                }
                .blog-img {
                    height : calc(100% - 45px);
                    background-size : contain;
                    background-repeat : no-repeat;
                    background-color : $mainColor;
                }
            }
           .slider-dots {
                bottom : -30px;
                button {
                    border : 1px solid $mainColor;
                    &[disabled] {
                        background: $mainColor;
                    }
                }
           }
            .slider-arrow-buttons {
                button {
                    i {
                        font-size : 40px;
                    }
                }
            }
        }
        .normal-blogs {
            padding-top : 30px;
            .search-and-filter {
                width : 100%;
                display : flex;
                flex-wrap : wrap;
                justify-content : center;
                .blog-select, .blog-search-input {
                    min-width : 200px;
                    max-width : 200px;
                    font-size : 18px;
                    margin : 5px 5px;
                    color : $mainColor;
                }
                .blog-select {
                    > div {
                        border : 1px solid $mainColor;
                        border-radius : 4px;
                        box-shadow : none;
                    }
                    .css-151xaom-placeholder {
                        color : fade-out($mainColor, 0.4);
                        font-weight : bold;
                    }
                }
                .blog-search-input {
                    height : 41px;
                    > input {
                        font-family : $mainFont;
                        color : $mainColor;
                        border : 1px solid $mainColor;
                        border-radius : 4px;
                        &::placeholder {
                            color : fade-out($mainColor, 0.4);
                        }
                    }
                }
            }
            .normal-blogs-wrap {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(300px, max-content));
                grid-gap: 16px;
                justify-content: center;
                padding : 15px;
            }
        }
        .blog-item {
            height : 300px;
            position : relative;
            //border : 1px solid #ccc;
            box-shadow : 0px 0px 4px -1px;
            border-radius : 4px;
            overflow : hidden;
            cursor : pointer;
            &:last-child {
                margin-right : 0px;
            }
            .blog-img {
                height : 100%;
                background-size : cover;
                background-position : center;
                h1 {
                    background: fade-out(darken($mainColor, 15%), 0.2);
                    color: #fff;
                    font-size: 21px;
                    font-family: "Barlow Condensed", sans-serif;
                    text-align: center;
                    padding: 10px 14px;
                    text-transform: uppercase;
                }
            }
            .blog-description {
                position : absolute;
                bottom : -5px;
                left : 0px;
                right : 0px;
                opacity : 0;
                background : fade-out(darken($mainColor, 10%), 0.15);
                font-size : 18px;
                padding : 10px 15px;
                transition : 300ms;
                color : #fff;
                a {
                    margin-left : 10px;
                    color : #ff625e;
                    float : right;
                    transition : 200ms;
                    &:hover {
                        color : darken(#ff625e, 15%);
                    }
                }
            }
            &:hover {
                .blog-description {
                    opacity : 1;
                    bottom : 0px;
                }
            }
        }
    }
    @media screen and (max-width : 767px) {
        width : auto;
    }
}

.basemaps-modal {
    margin : auto;
    max-width : 380px;
    color : #fff;
    .basemaps-header {
        position : relative;
        width : 100%;
        text-align : center;
        font-size : 28px;
        font-weight : bold;
        padding : 0 0 10px 0px;
        margin-bottom : 10px;
        font-family : $mainFont;
        .fa-times {
            @include close-button-mixin;
            position : absolute;
            top : 5px;
            right : 0px;
            font-size : 18px;
        }
    }
    .modal-content {
        padding : 12px;
        background : $mainColor;
        color : #fff;
        .ui.toggle.checkbox input~label, .ui.toggle.checkbox input:checked~label {
            margin-left : 3%;
            color : #fff !important;
            font-weight : bold;
            font-size : 13px;
        }
        .basemaps-container {
            display : flex;
            flex-wrap : wrap;
            > div {
                position : relative;
                width : 44%;
                height : 100px;
                background-size : cover;
                margin : 3%;
                border-radius : 12px;
                overflow : hidden;
                border : 1px solid #111;
                cursor : pointer;
                &.active {
                    border-width : 3px;
                    border-color : $accentRed;
                }
                label {
                    padding : 6px 0;
                    width : 100%;
                    font-size : 13px;
                    text-align : center;
                    font-weight : bold;
                    border-bottom : 1px solid #555;
                    background : rgba(0, 0, 0, 0.3);
                }
            }
        }
    }
}

#target-photos-button {
    position : absolute;
    bottom : 15px;
    right : 66px;
    width : 35px;
    height : 45px;
    @media screen and (max-width : 768px) {
        display : none;
    }
}

#target-basemaps {
    position : absolute;
    top : 80px;
    left : 50%;
    transform : translateX(-50%);
    width : 380px;
    height : 80vh;
}

#target-units {
    position : absolute;
    top : 0px;
    right : 0px;
    width : 100vw;
    height : 100vh;
}

// Place on top of basemaps modal
#react-joyride-step-1, #react-joyride-step-1 .__floater {
    z-index : 1051 !important;
}


@media screen and (max-width : 768px) {
    .ui.segment.donation-prompt {
        top : 102px;
    }
    .fa-question-circle {
        top : 12px;
        right : auto;
        left : 70px;
        bottom : auto;
    }
    .ui.segment.tutorial-prompt {
        bottom : $mobileSliderHeight - 5px;
    }
    #target-photos-button {
        bottom : $mobileSliderHeight + 10px;
    }
    .side-bar {
        top : $mobileHeaderHeight;
        bottom : auto;
        left : 0;
        right : 0;
        width : 100%;
        flex-direction : row;
        justify-content : space-around;
        padding : 10px 10px 6px;
        background : darken($mainColor, 5%);
        z-index : 11;
        .icon-wrap {
            display : inline-flex;
            flex-direction : column;
            align-items : center;
            margin : 0;
            .icon-label {
                display : block;
                color : rgba(255, 255, 255, 0.9);
                font-family : $mainFont;
                font-size : 16px;
                letter-spacing : 0.5px;
                text-transform : capitalize;
            }
        }
        i {
            margin : 4px 0px;
        }
        .drawer-panel {
            position : fixed;
            top : 110px;
            //bottom : 100%;
            right : -100%;
            width : 100%;
            max-width : none;

            &.active {
                //bottom : 0;
                right : 0;
                width : 100%;
                max-width : none;
            }
            .ui.segment.filters-box, .ui.segment.map-settings {
                max-height : 100%;
                //max-width : 80vw;
                min-width : 250px;
            }
        }
        .war-diaries-wrap .ui.vertical.sidebar {
            max-height : calc(100vh - #{$mobileSliderHeight} - #{$mobileHeaderHeight});
            max-width : 75vw;
        }
        .fa-wrench {
            display : none;
        }
        .story-map-link {
            display : none;
        }
        .tools-placeholders {
            display : none;
        }
    }
    .main-nav.bg-dark {
        max-width: 100%;
        width: 100%;
        border-radius: 0px;
        margin: 0px;
        justify-content: flex-end;
    }
    .navbar-nav {
        align-items : flex-end;
    }
    .navbar-dark .navbar-toggler {
        margin : 5px;
    }
    .main-nav {
        .navbar-brand {
            height : 57px;
            width : 55px;
        }
        .nav-item {
            margin: 8px 12px;
        }
    }
    .ui.inverted.menu {
        top: 0px;
        left: 0px;
        right: 0px;
        margin: 0px;
        border-radius: 0px;
    }
    .ui.inverted.menu a {
        padding: 10px;
    }
    .ui.button.war-diaries-toggle {
        top: 40px;
        margin-top: 1rem;
        width: 80px;
        left: 10px;
    }
    .ui.segment.time-slider-section {
        bottom: 0px;
        left: 0px;
        right: 0px;
        margin: 0px;
        width : auto;
        border-radius : 0px;
        padding : 0 0 8px;
        height : $mobileSliderHeight;
        transform : none;
    }
    .time-slider-section p {
        display: none;
    }
    .ui.segment.time-slider-section {
        .slider-wrap {
            display: flex;
            div {
                flex-grow: 1;
            }
            .rc-slider {
                margin-bottom: 0px;
            }
            .date {
               display: flex;
               align-items: center;
               justify-content: center;
               max-width: 160px;
            }
        }
        .play-buttons {
            > div {
                padding : 6px 16px;
                font-size : 14px;
                margin : 4px 5px 0;
                &.play-loop-toggle {
                    display : none;
                }
            }
        }
    }
    .mapboxgl-ctrl-bottom-right {
        bottom : $mobileSliderHeight - 30px;
        right : 0px;
    }
    .mapboxgl-ctrl-bottom-left {
        bottom : $mobileSliderHeight;
    }
}
@media screen and (max-width : 767px) {
    .fa-question-circle {
        right : 75px;
        left : auto;
    }
}

@media screen and (min-width : 1020px) {
    .cc {
      display : block !important;
      z-index: 100;
      background: $mainColor;
      font-family : $mainFont;
      color : #aaa;
      font-size: 14px;
      position: absolute;
      bottom: 0;
      left: 0;
      min-width: 240px;
      text-align: center;
      padding: 10px;
      border-top-right-radius: 3px;
      max-height: 40px;
      max-width: 305px;
      transition: 400ms;
      overflow: hidden;
      box-shadow : 0px 0px 4px -1px darken($mainColor, 15%);
      a {
            color : #fff;
            &:hover {
                color : #ccc;
            }
      }
      &:hover {
        max-height: 200px;
      }
    }
}

/* overwriting react-table cells "overflow" property so our dropdowns appear outside of the cells */
/* custom data-list page only */
.custom-data-list-page .ReactTable .rt-th, .ReactTable .rt-td {
  overflow: visible;
}
.blogs-table .rt-td {
  white-space : pre-wrap;
}

// z-index the unit search dropdown higher than the toggles
.css-1pcexqc-container {
    z-index : 5;
}

.marker {
  //background-image : url('/img/icons-draw/map-marker-alt-solid.svg');
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
  .mapboxgl-popup-content {
      background : transparent;
      box-shadow : none;
      .temp-marker {
          width: 25px;
          height: 25px;
      }
      i {
        font-size : 22px;
        color : $mainColor;
      }
  }
}


.slider-dots {
    text-align : center;
    position : absolute;
    bottom : 5px;
    left : 50%;
    transform : translateX(-50%);
    button {
        width : 18px;
        height : 18px;
        background : transparent;
        border : 1px solid #ccc;
        border-radius : 50%;
        margin : 3px;
        &[disabled] {
            background: #fff;
        }
        &:focus {
            outline : none;
        }
    }
}
.slider-arrow-buttons {
    position : absolute;
    top: 50%;
    width: 100%;
    height : 0px;
    display: flex;
    justify-content: space-between;
    button {
        background : none;
        border : none;
        &:focus {
            outline : none;
        }
        &[disabled] {
            opacity : 0.2;
        }
        i {
            font-size : 30px;
            text-shadow : 1px 1px #000;
            color : #fff;
        }
    }
}
