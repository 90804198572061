@import url(https://fonts.googleapis.com/css?family=Barlow+Condensed&display=swap);
@import url(https://fonts.googleapis.com/css?family=Barlow+Condensed&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.side-bar .drawer-panel.units-list-container {
  background: rgba(46, 59, 65, 0.9);
  overflow: auto;
  z-index: 4; }
  .side-bar .drawer-panel.units-list-container .units-filter {
    margin: 20px 20px 0;
    min-width: 310px; }
  .side-bar .drawer-panel.units-list-container .units-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px;
    margin: 10px;
    min-width: 330px; }
    .side-bar .drawer-panel.units-list-container .units-list .country-wrap {
      display: flex;
      flex-wrap: wrap;
      width: 100%; }
      .side-bar .drawer-panel.units-list-container .units-list .country-wrap .header {
        margin: 3px 0; }
    .side-bar .drawer-panel.units-list-container .units-list .unit-wrap {
      display: flex;
      flex-direction: column;
      margin: 3px 0;
      width: 100%; }
      .side-bar .drawer-panel.units-list-container .units-list .unit-wrap .unit-toggle {
        align-self: flex-end;
        margin: -2px 0 0 0;
        padding: 3px 8px 4px;
        background: #2e3b41;
        font-size: 12px;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        opacity: 1; }
    .side-bar .drawer-panel.units-list-container .units-list li {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-grow: 1;
      color: #fff;
      list-style: none;
      padding: 10px 8px;
      border-radius: 4px;
      background: #232d32;
      font-family: "Barlow Condensed", sans-serif;
      font-size: 15px;
      line-height: 1.2;
      letter-spacing: 0.5px;
      text-shadow: 1px 1px #000;
      -webkit-transition: 200ms;
      transition: 200ms;
      cursor: pointer;
      text-align: left; }
      .side-bar .drawer-panel.units-list-container .units-list li span {
        display: flex;
        align-items: center;
        padding-right: 12px; }
      .side-bar .drawer-panel.units-list-container .units-list li:hover {
        background: #0e1214; }
      .side-bar .drawer-panel.units-list-container .units-list li.army-group {
        margin-left: 12px; }
      .side-bar .drawer-panel.units-list-container .units-list li.division {
        margin-left: 18px; }
      .side-bar .drawer-panel.units-list-container .units-list li.crops {
        margin-left: 24px; }
      .side-bar .drawer-panel.units-list-container .units-list li.brigade {
        margin-left: 30px; }
      .side-bar .drawer-panel.units-list-container .units-list li.battalion {
        margin-left: 36px; }
      .side-bar .drawer-panel.units-list-container .units-list li.company {
        margin-left: 42px; }
      .side-bar .drawer-panel.units-list-container .units-list li.regiment {
        margin-left: 48px; }
      .side-bar .drawer-panel.units-list-container .units-list li.platoon {
        margin-left: 54px; }
      .side-bar .drawer-panel.units-list-container .units-list li.header {
        padding: 14px 10px;
        font-size: 18px;
        justify-content: space-between;
        border: 1px solid #2e3b41; }
        .side-bar .drawer-panel.units-list-container .units-list li.header.can {
          background: #941919; }
          .side-bar .drawer-panel.units-list-container .units-list li.header.can:hover {
            background: #691212; }
        .side-bar .drawer-panel.units-list-container .units-list li.header.gb {
          background: #7a6739; }
          .side-bar .drawer-panel.units-list-container .units-list li.header.gb:hover {
            background: #574a29; }
        .side-bar .drawer-panel.units-list-container .units-list li.header.nz {
          background: #7a6739; }
          .side-bar .drawer-panel.units-list-container .units-list li.header.nz:hover {
            background: #574a29; }
        .side-bar .drawer-panel.units-list-container .units-list li.header.sa {
          background: #7a6739; }
          .side-bar .drawer-panel.units-list-container .units-list li.header.sa:hover {
            background: #574a29; }
        .side-bar .drawer-panel.units-list-container .units-list li.header.fre {
          background: #2b7ac4; }
          .side-bar .drawer-panel.units-list-container .units-list li.header.fre:hover {
            background: #22609b; }
        .side-bar .drawer-panel.units-list-container .units-list li.header.pol {
          background: #d37a0d; }
          .side-bar .drawer-panel.units-list-container .units-list li.header.pol:hover {
            background: #a35e0a; }
        .side-bar .drawer-panel.units-list-container .units-list li.header.usa {
          background: #1a2b7a; }
          .side-bar .drawer-panel.units-list-container .units-list li.header.usa:hover {
            background: #111c50; }
        .side-bar .drawer-panel.units-list-container .units-list li.header.bra {
          background: #1a2b7a; }
          .side-bar .drawer-panel.units-list-container .units-list li.header.bra:hover {
            background: #111c50; }
        .side-bar .drawer-panel.units-list-container .units-list li.header.gmn {
          background: #0b0909; }
          .side-bar .drawer-panel.units-list-container .units-list li.header.gmn:hover {
            background: black; }
        .side-bar .drawer-panel.units-list-container .units-list li.header.ita {
          background: #30933d; }
          .side-bar .drawer-panel.units-list-container .units-list li.header.ita:hover {
            background: #236c2d; }
        .side-bar .drawer-panel.units-list-container .units-list li.header.ind {
          background: #7a6739; }
          .side-bar .drawer-panel.units-list-container .units-list li.header.ind:hover {
            background: #574a29; }
        .side-bar .drawer-panel.units-list-container .units-list li.header.jpn {
          background: #e37203; }
          .side-bar .drawer-panel.units-list-container .units-list li.header.jpn:hover {
            background: #b05902; }
        .side-bar .drawer-panel.units-list-container .units-list li.header.cze {
          background: #7a6739; }
          .side-bar .drawer-panel.units-list-container .units-list li.header.cze:hover {
            background: #574a29; }
        .side-bar .drawer-panel.units-list-container .units-list li.header i {
          font-size: 16px; }
      .side-bar .drawer-panel.units-list-container .units-list li.unit {
        border-left: 2px solid; }
        .side-bar .drawer-panel.units-list-container .units-list li.unit .fa-book-open {
          font-size: 18px;
          opacity: 0.8; }
        .side-bar .drawer-panel.units-list-container .units-list li.unit.gmn {
          border-color: #191515; }
        .side-bar .drawer-panel.units-list-container .units-list li.unit.gb {
          border-color: #8b7641; }
        .side-bar .drawer-panel.units-list-container .units-list li.unit.can {
          border-color: #aa1d1d; }
        .side-bar .drawer-panel.units-list-container .units-list li.unit.usa {
          border-color: #1e338f; }
        .side-bar .drawer-panel.units-list-container .units-list li.unit.pol {
          border-color: #eb880f; }
        .side-bar .drawer-panel.units-list-container .units-list li.unit.fre {
          border-color: #3687d3; }
        .side-bar .drawer-panel.units-list-container .units-list li.unit.ita {
          border-color: #36a645; }
        .side-bar .drawer-panel.units-list-container .units-list li.unit.nz {
          border-color: #8b7641; }
        .side-bar .drawer-panel.units-list-container .units-list li.unit.bra {
          border-color: #1e338f; }
        .side-bar .drawer-panel.units-list-container .units-list li.unit.ind {
          border-color: #8b7641; }
        .side-bar .drawer-panel.units-list-container .units-list li.unit.sa {
          border-color: #8b7641; }
      .side-bar .drawer-panel.units-list-container .units-list li .unit-icon {
        width: 24px;
        min-width: 24px;
        color: #fff;
        opacity: 0.2;
        font-size: 22px;
        font-family: simple;
        margin-right: 10px;
        text-align: center;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        /*
                &.gb {
                    color: #cf142b;
                }

                &.can {
                    color: #ff0000;
                }

                &.usa {
                    color: #3c3b6e;
                }

                &.pol {
                    color: #c36b13;
                }

                &.fre {
                    color: #0055a4;
                }
                */ }
      .side-bar .drawer-panel.units-list-container .units-list li.disabled.unit {
        display: none; }
      .side-bar .drawer-panel.units-list-container .units-list li i {
        margin: 0;
        font-size: 20px; }

@media screen and (max-width: 768px) {
  .side-bar .units-list-mobile-container {
    position: absolute;
    /*
        position : absolute;
        top : 0px;
        bottom : 0px;
        right : 0px;
        //padding : 30px;
        max-width : 0px;
        overflow : auto;
        transition : 600ms;
        &.active {
           max-width : 400px;
           width : 450px;
           transition : 600ms;
        }
        */ }
    .side-bar .units-list-mobile-container .drawer-panel.active {
      max-width: 0px;
      background: #2e3b41;
      border-top: 1px solid #232d32; }
    .side-bar .units-list-mobile-container.active .drawer-panel.active {
      max-width: 450px; } }

.main-diary {
  margin: 10px;
  text-align: left;
  padding: 10px;
  /*background: rgba(255,255,255,0.8);
  border-radius: 4px;
  */
  color: #000;
  padding: 8px; }

.main-diary p {
  line-height: 1.55em;
  color: #2e3b41;
  font-family: arial; }

.main-diary iframe {
  height: auto;
  width: 100%; }

.diary-internal .c3 {
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 9pt;
  font-family: "Arial";
  font-style: normal; }

.diary-internal .c0 {
  padding-top: 0pt;
  padding-bottom: 5pt;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt; }

.diary-internal .c1 {
  padding-top: 0pt;
  padding-bottom: 5pt;
  orphans: 2;
  widows: 2;
  text-align: left; }

.diary-internal .c15 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  orphans: 2;
  widows: 2;
  text-align: left; }

.diary-internal .c9 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  orphans: 2;
  widows: 2;
  text-align: left; }

.diary-internal .c21 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  orphans: 2;
  widows: 2;
  text-align: left; }

.diary-internal .c16 {
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
  vertical-align: baseline;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
  font-style: normal; }

.diary-internal .c23 {
  font-weight: 700;
  font-size: 8pt;
  font-family: "Arial"; }

.diary-internal .c17 {
  font-weight: 700;
  font-size: 11pt;
  font-family: "Arial"; }

.diary-internal .c30 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  text-align: left; }

.diary-internal .c20 {
  font-weight: 700;
  font-size: 11pt;
  font-family: "Calibri"; }

.diary-internal .c24 {
  font-weight: 700;
  font-size: 12pt;
  font-family: "Times New Roman"; }

.diary-internal .c29 {
  margin-left: -5.4pt;
  border-spacing: 0;
  border-collapse: collapse;
  margin-right: auto; }

.diary-internal .c11 {
  font-weight: 400;
  font-size: 9pt;
  font-family: "Arial"; }

.diary-internal .c2 {
  font-size: 11pt;
  font-family: "Arial";
  font-weight: 400; }

.diary-internal .c4 {
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal; }

.diary-internal .c25 {
  font-weight: 400;
  font-size: 11pt;
  font-family: "Arial"; }

.diary-internal .c18 {
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

.diary-internal .c13 {
  text-decoration: none;
  vertical-align: baseline;
  font-style: italic; }

.diary-internal .c7 {
  -webkit-text-decoration-skip: none;
  text-decoration: line-through;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

.diary-internal .c27 {
  background-color: #ffffff;
  max-width: 468pt;
  padding: 72pt 72pt 72pt 72pt; }

.diary-internal .c19 {
  vertical-align: baseline;
  font-style: normal; }

.diary-internal .c26 {
  font-weight: 400;
  font-family: "Arial"; }

.diary-internal .c10 {
  height: 0pt; }

.diary-internal .c14 {
  font-style: italic; }

.diary-internal .c22 {
  font-style: normal; }

.diary-internal .c12 {
  height: 11pt; }

.diary-internal .c28 {
  background-color: #ffff00; }

.diary-internal .title {
  padding-top: 24pt;
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  font-family: "Calibri";
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left; }

.diary-internal .subtitle {
  padding-top: 18pt;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: "Georgia";
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left; }

.diary-list {
  max-width: 300px;
  background: rgba(46, 59, 65, 0.9) !important; }
  .diary-list .ui.header {
    margin-top: 25px;
    font-size: 24px;
    font-family: "Barlow Condensed", sans-serif; }
    .diary-list .ui.header i {
      font-size: 20px;
      border: 1px solid #ccc;
      border-radius: 50%;
      height: 35px;
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      -webkit-transition: 150ms;
      transition: 150ms;
      opacity: 1;
      position: absolute;
      left: 24px;
      top: 23px;
      font-size: 22px;
      text-shadow: none;
      color: #2e3b41; }
      .diary-list .ui.header i:hover {
        opacity: 0.6; }

.diary-arrows.play-buttons {
  background: transparent;
  display: flex;
  justify-content: center;
  margin: 0;
  padding-top: 10px; }
  .diary-arrows.play-buttons > div {
    margin: 0px 5px;
    border-radius: 4px;
    color: #fff;
    background: #192023;
    padding: 5px 0px;
    width: 70px; }
    .diary-arrows.play-buttons > div i {
      font-size: 20px;
      margin: 0px;
      padding: 0px; }
    .diary-arrows.play-buttons > div:hover {
      background: #192023;
      color: #000; }

.ui.visible.push.sidebar.war-diaries {
  overflow: hidden !important;
  display: flex;
  border: none;
  border-right: 1px solid; }

.war-diaries .ui.header {
  background: #2e3b41;
  color: #fff;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 24px; }
  .war-diaries .ui.header.main-war-diaries-header {
    margin-bottom: 0px; }
    .war-diaries .ui.header.main-war-diaries-header i.fa-times {
      font-size: 20px;
      border: 1px solid #ccc;
      border-radius: 50%;
      height: 35px;
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      -webkit-transition: 150ms;
      transition: 150ms;
      opacity: 1;
      position: absolute;
      top: 13px;
      left: 13px;
      font-size: 16px;
      height: 30px;
      width: 30px; }
      .war-diaries .ui.header.main-war-diaries-header i.fa-times:hover {
        opacity: 0.6; }
    .war-diaries .ui.header.main-war-diaries-header .text-size-controls {
      position: absolute;
      top: 10px;
      right: 10px; }
      .war-diaries .ui.header.main-war-diaries-header .text-size-controls i {
        margin: 4px;
        font-size: 18px; }
  .war-diaries .ui.header.date {
    /* margin-left : -40px; */
    border-radius: 3px;
    padding: 10px;
    text-transform: uppercase; }
    .war-diaries .ui.header.date span {
      position: absolute;
      left: -30px;
      color: green; }
  .war-diaries .ui.header h1 {
    font-family: "Barlow Condensed", sans-serif;
    font-size: 22px;
    padding: 14px 8px;
    width: 100%; }
    .war-diaries .ui.header h1.source {
      font-size: 18px;
      padding: 0; }
  .war-diaries .ui.header span {
    display: inline-block; }

.war-diary-buttons {
  height: 52px;
  background: #2e3b41;
  text-align: center;
  padding: 6px; }
  .war-diary-buttons .ui.button {
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 16px;
    background: #192023; }

.ui.vertical.sidebar {
  position: relative;
  /*
    max-height : 100vh;
    min-height : 100vh;
    overflow : auto;
    */ }
  .ui.vertical.sidebar .ui.toggle.checkbox input ~ label, .ui.vertical.sidebar .ui.toggle.checkbox input:checked ~ label {
    color: #fff !important;
    font-family: "Barlow Condensed", sans-serif;
    letter-spacing: 0.6px;
    font-size: 18px;
    margin-bottom: 15px;
    text-align: left; }
  .ui.vertical.sidebar .war-diary-menu {
    display: flex;
    list-style: none;
    width: 100%;
    padding: 0;
    margin: 0; }
    .ui.vertical.sidebar .war-diary-menu li {
      border: 1px solid #192023;
      border-right: none;
      flex-grow: 1;
      padding: 3px 0px;
      font-size: 18px;
      cursor: pointer;
      -webkit-transition: 200ms;
      transition: 200ms; }
      .ui.vertical.sidebar .war-diary-menu li.active {
        background: #192023; }
      .ui.vertical.sidebar .war-diary-menu li:hover:not(.active) {
        background: #232d32; }
  .ui.vertical.sidebar .unit-info {
    font-family: "Barlow Condensed", sans-serif;
    font-size: 18px;
    padding-top: 16px; }
    .ui.vertical.sidebar .unit-info .unit-data-list {
      padding: 0px;
      margin: 30px 0 30px 20px;
      text-align: left;
      list-style: none;
      font-family: "Barlow Condensed", sans-serif; }
      .ui.vertical.sidebar .unit-info .unit-data-list label {
        font-weight: bold;
        opacity: 0.6;
        text-transform: uppercase;
        font-size: 17px;
        min-width: 100px; }
    .ui.vertical.sidebar .unit-info .wiki-content, .ui.vertical.sidebar .unit-info .diary-not-found-content {
      text-align: left;
      line-height: 1.55em;
      color: #2e3b41;
      font-family: arial;
      margin: 15px; }
      .ui.vertical.sidebar .unit-info .wiki-content h1, .ui.vertical.sidebar .unit-info .diary-not-found-content h1 {
        font-size: 1.5em;
        margin: 8px 0px;
        font-family: "Barlow Condensed", sans-serif;
        color: #000;
        font-weight: bold; }
    .ui.vertical.sidebar .unit-info a {
      font-size: 18px;
      font-family: "Barlow Condensed", sans-serif;
      color: #000;
      margin-bottom: 15px;
      -webkit-transition: 300ms;
      transition: 300ms;
      font-weight: bold; }
      .ui.vertical.sidebar .unit-info a i {
        font-size: 16px;
        text-shadow: none;
        color: inherit;
        opacity: 1;
        margin-left: 3px;
        -webkit-transition: none;
        transition: none; }
      .ui.vertical.sidebar .unit-info a:hover {
        text-decoration: none;
        color: #841105; }

.diaries {
  overflow: auto;
  border-left: 1px solid #ccc; }

@media screen and (max-width: 768px) {
  .ui.visible.push.sidebar.war-diaries {
    width: 100%;
    border: none;
    border-top: 1px solid #232d32; }
  .war-diary-buttons {
    width: 100%; } }

@font-face {
  font-family: 'allied';
  src: local("P44AlliedMarkers"), url(/static/media/P44AlliedMarkers.09f40d9e.ttf) format("truetype"); }

@font-face {
  font-family: 'simple';
  src: local("P44Silheouttes"), url(/static/media/P44Silheouttes.489f1733.ttf) format("truetype"); }

@font-face {
  font-family: 'german';
  src: local("P44GermanMarkers"), url(/static/media/P44GermanMarkers.4466a42d.ttf) format("truetype"); }

@font-face {
  font-family: 'nato';
  src: local("P44NatodMarkers"), url(/static/media/P44NatoMarkers.215eb2bc.ttf) format("truetype"); }

/* main app framework */
.capitalize {
  text-transform: capitalize; }

.uppercase {
  text-transform: uppercase; }

.tox .tox-notification--warning {
  display: none !important; }

.ui.segment.tutorial-prompt, .ui.segment.donation-prompt {
  position: absolute;
  max-width: 250px;
  bottom: 0px;
  right: 60px;
  z-index: 10;
  padding: 15px;
  font-size: 18px;
  font-family: "Barlow Condensed", sans-serif;
  line-height: 30px; }
  .ui.segment.tutorial-prompt div, .ui.segment.donation-prompt div {
    width: 100%;
    text-align: center; }
    .ui.segment.tutorial-prompt div .ui.button, .ui.segment.donation-prompt div .ui.button {
      margin-top: 10px;
      font-family: "Barlow Condensed", sans-serif;
      font-size: 18px;
      padding: 12px 16px; }
  .ui.segment.tutorial-prompt .fa-question-circle, .ui.segment.donation-prompt .fa-question-circle {
    position: relative;
    top: 0;
    right: 0;
    font-size: 18px;
    pointer-events: none; }

.fa-question-circle {
  position: absolute;
  right: 15px;
  bottom: 75px;
  z-index: 20;
  color: #688fc2;
  font-size: 22px;
  cursor: pointer;
  -webkit-transition: 200ms;
  transition: 200ms; }
  .fa-question-circle:hover {
    opacity: 0.6; }

.ui.segment.donation-prompt {
  top: 80px;
  left: -350px;
  max-width: 320px;
  bottom: auto;
  right: auto;
  font-family: "Barlow Condensed", sans-serif;
  -webkit-transition: 500ms;
  transition: 500ms; }
  .ui.segment.donation-prompt.active {
    left: 10px; }
  .ui.segment.donation-prompt h1 {
    font-size: 30px;
    font-family: "Barlow Condensed", sans-serif;
    text-align: center; }
    .ui.segment.donation-prompt h1 a {
      text-decoration: none; }
  .ui.segment.donation-prompt p {
    font-size: 17px; }
    .ui.segment.donation-prompt p a {
      font-size: inherit; }
  .ui.segment.donation-prompt a {
    color: #b60f0c;
    -webkit-transition: 150ms;
    transition: 150ms;
    text-decoration: underline; }
    .ui.segment.donation-prompt a:hover {
      color: #f0221e; }

/* Zoom & Geolocate offset from sidebar */
.mapboxgl-ctrl-top-right, .mapboxgl-ctrl-bottom-right {
  right: 60px; }

.mapboxgl-ctrl-top-right {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  right: 400px; }
  @media screen and (max-width: 768px) {
    .mapboxgl-ctrl-top-right {
      top: 110px;
      right: 0; } }
  @media screen and (max-width: 500px) {
    .mapboxgl-ctrl-top-right .mapboxgl-ctrl-geocoder {
      min-width: 200px;
      max-width: 200px; } }
  @media screen and (max-width: 768px) {
    .mapboxgl-ctrl-top-right .mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--input {
      height: 36px;
      padding: 6px 34px; }
      .mapboxgl-ctrl-top-right .mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--input::-webkit-input-placeholder {
        font-size: 14px; }
      .mapboxgl-ctrl-top-right .mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--input::-moz-placeholder {
        font-size: 14px; }
      .mapboxgl-ctrl-top-right .mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--input::-ms-input-placeholder {
        font-size: 14px; }
      .mapboxgl-ctrl-top-right .mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--input::placeholder {
        font-size: 14px; }
    .mapboxgl-ctrl-top-right .mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--icon {
      top: 9px;
      left: 6px;
      width: 20px;
      height: 20px; } }

/* Make sure  mapbox attribution isn't hidden */
.mapboxgl-ctrl-bottom-left {
  bottom: 40px; }

/* popups */
.no-container .mapboxgl-popup-content {
  padding: 0;
  background: none;
  border: none;
  box-shadow: none;
  max-width: 50px;
  pointer-events: none;
  text-align: center; }

.no-container .mapboxgl-popup-content img {
  width: 30px;
  height: 30px;
  margin: 0 auto; }

.no-container .text {
  text-align: center;
  text-shadow: white 2px 2px 5px;
  background: rgba(255, 255, 255, 0.9);
  font-size: 9px;
  padding: 2px;
  line-height: 10px; }

.icon-image.larger {
  width: 33px !important;
  height: 33px !important; }

.poi-icon {
  max-width: 33px;
  max-height: 33px; }

.poi-popup-details {
  width: 250px; }

.poi-row {
  margin: 5px 10px; }

.poi-row input {
  width: 200px; }

.photo-modal {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 20px;
  max-width: 700px; }
  .photo-modal .close {
    font-size: 40px; }
  .photo-modal .modal-title {
    font-family: "Barlow Condensed", sans-serif;
    font-size: 30px; }
  .photo-modal .description {
    text-align: center;
    margin: 20px; }
  .photo-modal .slider-img {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #2e3b41;
    border-radius: 2px; }
  .photo-modal a {
    word-break: break-all; }
  .photo-modal iframe {
    max-width: 80vw; }

/* maps */
#map {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  /* z-index: -10; */
  outline: none; }

.background-map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: -10;
  overflow: hidden; }

.background-map img {
  width: 100%; }

/* sidebar */
.sidebar-area {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0px 10px;
  width: 250px;
  margin-right: -250px;
  z-index: -5;
  background: #555;
  -webkit-transition: 0.5s;
  transition: 0.5s; }

.sidebar-area h3 {
  margin-top: 70px;
  color: #eee; }

.sidebar-area.open {
  margin-right: 0px; }

.sidebar-area .navbar-toggler {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  position: absolute;
  background: #555;
  left: 0;
  margin-left: -50px; }

/* bottom */
.bottom-area {
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
  background: #555;
  height: 270px;
  margin-bottom: -250px;
  -webkit-transition: 0.5s;
  transition: 0.5s; }

.bottom-area.open {
  margin-bottom: 0px; }

.bottom-area .map-list img {
  width: 23%;
  margin: 0px 10px;
  display: inline-block; }

.bottom-area .navbar-toggler {
  background: #555;
  margin-top: -20px; }

.modal-close {
  right: 1rem;
  position: absolute; }

/* Header */
.main-nav .navbar-brand {
  background-image: url("/img/PROJECT-44-Favicon-POS_Patreon.png");
  height: 100px;
  width: 110px;
  position: absolute;
  top: -5px;
  left: 0;
  background-size: cover; }

.main-nav a {
  color: #fff; }
  .main-nav a:hover {
    text-decoration: none; }

.main-nav.bg-dark {
  font-family: "Barlow Condensed", sans-serif;
  z-index: 12;
  position: absolute;
  border-bottom-right-radius: 4px;
  color: #fff;
  background-color: #2e3b41 !important;
  padding: 0 5px 0 110px;
  box-shadow: 1px 1px 1px -1px #0e1214; }

.main-nav .nav-item {
  margin: 8px 8px;
  font-size: 20px;
  white-space: nowrap;
  cursor: pointer;
  text-transform: uppercase;
  -webkit-transition: 200ms;
  transition: 200ms; }
  .main-nav .nav-item:hover {
    color: #6f8c99; }
    .main-nav .nav-item:hover a {
      color: #6f8c99; }
  .main-nav .nav-item .nav-link {
    color: white !important;
    padding: 0px; }
  .main-nav .nav-item a {
    color: white; }

.filtered {
  opacity: 0.4; }

/* Loading panel */
#loading-container {
  z-index: 100;
  position: absolute;
  top: 25%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  font-size: 2em;
  font-weight: bolder;
  min-width: 240px;
  text-align: center;
  background: #fff;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 0px 0px 5px -1px #aaa; }
  #loading-container span {
    font-family: "Barlow Condensed", sans-serif;
    font-size: 26px;
    margin-bottom: 10px;
    display: inline-block; }
  #loading-container .month-status {
    display: flex;
    flex-wrap: wrap; }
    #loading-container .month-status div {
      opacity: 0.2;
      -webkit-transition: 100ms;
      transition: 100ms;
      font-size: 16px;
      width: 50%;
      font-weight: normal;
      text-transform: uppercase;
      margin-bottom: 18px;
      font-family: "Barlow Condensed", sans-serif; }
      #loading-container .month-status div.loaded {
        opacity: 1; }
        #loading-container .month-status div.loaded .fa-check-circle {
          color: #66da81; }
      #loading-container .month-status div .fa-check-circle {
        margin-top: 5px;
        display: block;
        font-size: 22px; }

/* Side bar */
.side-bar {
  position: absolute;
  z-index: 3;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background: #2e3b41;
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  width: 50px;
  box-shadow: 0px 0px 4px -1px #0e1214; }
  .side-bar .icon-wrap {
    margin: 17px 0px; }
    .side-bar .icon-wrap i {
      margin: 0; }
    .side-bar .icon-wrap .icon-label {
      display: none; }
  .side-bar i {
    color: #fff;
    font-size: 26px;
    margin: 17px 0px;
    opacity: 0.6;
    cursor: pointer;
    -webkit-transition: 200ms;
    transition: 200ms; }
    .side-bar i:last-of-type {
      margin-top: auto; }
    .side-bar i.active {
      opacity: 1;
      -webkit-transform: scale(1.2);
              transform: scale(1.2); }
  .side-bar .story-map-link {
    width: 40px;
    height: 40px;
    margin-bottom: 20px; }
    .side-bar .story-map-link .story-map-icon {
      display: inline-block;
      width: 40px;
      height: 40px;
      background-image: url("/img/story-map-icon.png");
      background-size: cover;
      -webkit-filter: brightness(0) invert(1);
              filter: brightness(0) invert(1);
      margin-top: 10px;
      cursor: pointer; }
  .side-bar .drawer-panel {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 50px;
    max-width: 0px;
    overflow: hidden;
    -webkit-transition: 600ms;
    transition: 600ms;
    z-index: 6; }
    .side-bar .drawer-panel.active {
      max-width: 400px;
      width: 350px;
      -webkit-transition: 600ms;
      transition: 600ms; }
    .side-bar .drawer-panel .layersContainer .ui.segment.map-settings, .side-bar .drawer-panel .settingsContainer .ui.segment.map-settings, .side-bar .drawer-panel .iconsContainer .ui.segment.map-settings {
      background: transparent !important;
      height: auto !important; }
    .side-bar .drawer-panel .ui.segment.filters-box, .side-bar .drawer-panel .ui.segment.map-settings {
      padding: 0px;
      height: 100vh;
      min-width: 340px;
      z-index: 3;
      -webkit-transition: right 1s;
      transition: right 1s;
      background: #2e3b41;
      overflow: auto;
      border-radius: 0px;
      font-family: Arial; }
      .side-bar .drawer-panel .ui.segment.filters-box .ui.header, .side-bar .drawer-panel .ui.segment.map-settings .ui.header {
        color: #fff;
        margin: 10px 0px;
        padding: 5px 0px;
        font-family: "Barlow Condensed", sans-serif;
        font-size: 20px;
        text-align: center;
        /*
              border-top : 2px solid #000;
              border-bottom : 2px solid #000;
              */
        background: #192023;
        text-transform: capitalize;
        letter-spacing: 1px; }
        .side-bar .drawer-panel .ui.segment.filters-box .ui.header i.fa-times, .side-bar .drawer-panel .ui.segment.map-settings .ui.header i.fa-times {
          font-size: 20px;
          border: 1px solid #ccc;
          border-radius: 50%;
          height: 35px;
          width: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          -webkit-transition: 150ms;
          transition: 150ms;
          opacity: 1;
          position: absolute;
          top: 12px;
          left: 13px;
          font-size: 12px;
          height: 25px;
          width: 25px;
          opacity: 0.8; }
          .side-bar .drawer-panel .ui.segment.filters-box .ui.header i.fa-times:hover, .side-bar .drawer-panel .ui.segment.map-settings .ui.header i.fa-times:hover {
            opacity: 0.6; }
        .side-bar .drawer-panel .ui.segment.filters-box .ui.header.accordion-title, .side-bar .drawer-panel .ui.segment.map-settings .ui.header.accordion-title {
          margin: 0px;
          padding: 10px 0px;
          border: none;
          background: #192023;
          margin-bottom: 15px;
          font-size: 24px; }
      .side-bar .drawer-panel .ui.segment.filters-box .item .ui.header i.fa-angle-double-right, .side-bar .drawer-panel .ui.segment.map-settings .item .ui.header i.fa-angle-double-right {
        float: left;
        margin: 0px -10px 0px 8px; }
      .side-bar .drawer-panel .ui.segment.filters-box .item .accordion, .side-bar .drawer-panel .ui.segment.map-settings .item .accordion {
        max-height: 0px;
        overflow: hidden;
        -webkit-transition: 400ms;
        transition: 400ms; }
        .side-bar .drawer-panel .ui.segment.filters-box .item .accordion.active, .side-bar .drawer-panel .ui.segment.map-settings .item .accordion.active {
          max-height: 400px;
          overflow: auto; }
        .side-bar .drawer-panel .ui.segment.filters-box .item .accordion .icon-type label, .side-bar .drawer-panel .ui.segment.map-settings .item .accordion .icon-type label {
          width: 110px;
          display: flex;
          justify-content: space-between; }
          .side-bar .drawer-panel .ui.segment.filters-box .item .accordion .icon-type label span, .side-bar .drawer-panel .ui.segment.map-settings .item .accordion .icon-type label span {
            font-size: 26px;
            opacity: 0.6; }
      .side-bar .drawer-panel .ui.segment.filters-box .ui.toggle.checkbox, .side-bar .drawer-panel .ui.segment.map-settings .ui.toggle.checkbox {
        margin-left: 5px; }
      .side-bar .drawer-panel .ui.segment.filters-box .ui.toggle.checkbox input ~ label, .side-bar .drawer-panel .ui.segment.filters-box .ui.toggle.checkbox input:checked ~ label, .side-bar .drawer-panel .ui.segment.map-settings .ui.toggle.checkbox input ~ label, .side-bar .drawer-panel .ui.segment.map-settings .ui.toggle.checkbox input:checked ~ label {
        color: #fff !important;
        font-family: Arial;
        letter-spacing: 0.6px;
        font-size: 13px; }
      .side-bar .drawer-panel .ui.segment.filters-box .ui.slider.checkbox, .side-bar .drawer-panel .ui.segment.map-settings .ui.slider.checkbox {
        display: block;
        margin-top: 20px; }
      .side-bar .drawer-panel .ui.segment.filters-box label, .side-bar .drawer-panel .ui.segment.map-settings label {
        color: rgba(255, 255, 255, 0.9);
        margin-left: 12px; }
      .side-bar .drawer-panel .ui.segment.filters-box .ui.radio.checkbox, .side-bar .drawer-panel .ui.segment.map-settings .ui.radio.checkbox {
        width: 100%;
        margin-top: 12px; }
      .side-bar .drawer-panel .ui.segment.filters-box .ui.radio.checkbox input ~ label, .side-bar .drawer-panel .ui.segment.map-settings .ui.radio.checkbox input ~ label {
        color: #fff; }
    .side-bar .drawer-panel .basemap-options {
      padding-left: 10px;
      letter-spacing: 0.6px;
      font-size: 13px; }
      .side-bar .drawer-panel .basemap-options > div {
        cursor: pointer; }
        .side-bar .drawer-panel .basemap-options > div:hover label {
          opacity: 1; }
        .side-bar .drawer-panel .basemap-options > div:hover img {
          -webkit-transform: scale(1.05);
                  transform: scale(1.05); }
        .side-bar .drawer-panel .basemap-options > div.active label {
          opacity: 1; }
        .side-bar .drawer-panel .basemap-options > div.active img {
          border-color: #9e1907;
          box-shadow: 0px 0px 0px 1px #902d2d;
          -webkit-transform: scale(1.1);
                  transform: scale(1.1); }
        .side-bar .drawer-panel .basemap-options > div label {
          opacity: 0.7;
          cursor: pointer;
          -webkit-transition: 150ms;
          transition: 150ms;
          color: #fff; }
        .side-bar .drawer-panel .basemap-options > div img {
          width: 60px;
          height: 40px;
          margin: 5px;
          border: 1px solid #0e1214;
          border-radius: 2px;
          cursor: pointer;
          -webkit-transition: 200ms;
          transition: 200ms; }
  .side-bar .info-text {
    position: absolute;
    right: 60px;
    bottom: 50px;
    background: rgba(255, 255, 255, 0.8);
    padding: 5px 10px;
    border-radius: 10px;
    min-width: 200px;
    box-shadow: 2px 2px 2px #ccc; }
  .side-bar .tools-placeholders {
    position: absolute;
    display: flex;
    right: 60px;
    bottom: 10px; }
    .side-bar .tools-placeholders i {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background: #2e3b41;
      padding: 10px;
      width: 43px;
      height: 43px;
      border-radius: 50px;
      opacity: 1;
      margin: 4px;
      box-shadow: 1px 1px 2px 1px #0e1214; }
      .side-bar .tools-placeholders i:hover {
        background: #040505; }
      .side-bar .tools-placeholders i.active {
        background: #040505;
        -webkit-transform: scale(1.1);
                transform: scale(1.1); }

.magnifying-glass-tool {
  position: fixed;
  background-color: #fff;
  background-repeat: no-repeat;
  z-index: 1;
  height: 200px;
  width: 200px;
  border: 1px solid #2e3b41;
  box-shadow: 0px 0px 2px 1px;
  border-radius: 50%;
  pointer-events: none;
  cursor: none; }

/* Edit Units section */
.ui.segment.edit-units-section {
  font-family: "Barlow Condensed", sans-serif;
  background: #2e3b41;
  z-index: 10;
  position: absolute;
  bottom: 0px;
  left: 0px;
  margin: 0px;
  border-radius: 0px;
  border-top-right-radius: 3px;
  box-shadow: -1px -1px 2px -1px #0e1214;
  min-height: 200px; }
  .ui.segment.edit-units-section.hidden {
    max-height: 0px;
    min-height: 0px;
    padding: 0px;
    overflow: hidden; }
  .ui.segment.edit-units-section .edit-units-header {
    display: flex;
    justify-content: space-between; }
    .ui.segment.edit-units-section .edit-units-header .select-wrap {
      flex-grow: 2;
      color: #000;
      font-size: 16px;
      margin-right: 10px;
      max-width: 350px; }
    .ui.segment.edit-units-section .edit-units-header .play-buttons {
      overflow: hidden;
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 10px;
      max-width: 135px; }
      .ui.segment.edit-units-section .edit-units-header .play-buttons > div {
        padding: 5px 10px; }
      .ui.segment.edit-units-section .edit-units-header .play-buttons .date {
        font-size: 20px;
        pointer-events: none;
        width: auto; }
      .ui.segment.edit-units-section .edit-units-header .play-buttons i {
        font-size: 16px; }
    .ui.segment.edit-units-section .edit-units-header i.fa-times {
      font-size: 20px;
      border: 1px solid #ccc;
      border-radius: 50%;
      height: 35px;
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      -webkit-transition: 150ms;
      transition: 150ms;
      opacity: 1;
      font-size: 13px;
      height: 25px;
      width: 25px;
      margin-top: 4px; }
      .ui.segment.edit-units-section .edit-units-header i.fa-times:hover {
        opacity: 0.6; }
  .ui.segment.edit-units-section .ui.button {
    font-family: "Barlow Condensed", sans-serif;
    font-size: 13px;
    letter-spacing: 0.4px; }
    .ui.segment.edit-units-section .ui.button.add-button {
      padding: 14px;
      font-size: 16px;
      font-weight: normal; }
  .ui.segment.edit-units-section .edit-units-table {
    max-width: 1000px;
    margin-bottom: 2px;
    margin-top: 0px;
    -webkit-transition: 300ms;
    transition: 300ms;
    font-family: arial;
    font-size: 13px; }
    .ui.segment.edit-units-section .edit-units-table.hidden {
      display: none; }
    .ui.segment.edit-units-section .edit-units-table.inactive {
      pointer-events: none;
      opacity: 0.3; }
  .ui.segment.edit-units-section .ui.input {
    max-width: 100px; }
  .ui.segment.edit-units-section table.ui.celled tbody tr td {
    padding: 5px;
    pointer-events: all !important;
    cursor: pointer;
    vertical-align: middle; }
  .ui.segment.edit-units-section table.ui.celled tbody tr.edit-row {
    background: #e6eafb; }
    .ui.segment.edit-units-section table.ui.celled tbody tr.edit-row td {
      border-bottom: 1px solid rgba(34, 36, 38, 0.1); }
  .ui.segment.edit-units-section .ui.pagination.menu .item {
    font-family: "Barlow Condensed", sans-serif; }

/* Poi Window */
.ui.segment.poi-window, .ui.segment.feature-edit-window {
  font-family: Arial;
  background: #2e3b41;
  z-index: 10;
  position: absolute;
  top: 100px;
  left: 10px;
  margin: 0px;
  border-radius: 3px;
  border-top-right-radius: 3px;
  box-shadow: 0px 1px 2px -1px #0e1214;
  z-index: 100;
  padding: 20px 10px 12px; }
  .ui.segment.poi-window .ui.form .field, .ui.segment.feature-edit-window .ui.form .field {
    position: relative; }
    .ui.segment.poi-window .ui.form .field > label, .ui.segment.feature-edit-window .ui.form .field > label {
      position: absolute;
      top: -8px;
      background: #2e3b41;
      color: rgba(255, 255, 255, 0.5);
      font-weight: normal;
      padding: 0px 5px;
      left: 10px;
      z-index: 1;
      letter-spacing: 0.6px; }
  .ui.segment.poi-window .ui.input input, .ui.segment.poi-window .ui.form textarea,
  .ui.segment.poi-window .ui.form .color-picker,
  .ui.segment.poi-window .ui.form .opacity-picker,
  .ui.segment.poi-window .ui.form .marker-options, .ui.segment.feature-edit-window .ui.input input, .ui.segment.feature-edit-window .ui.form textarea,
  .ui.segment.feature-edit-window .ui.form .color-picker,
  .ui.segment.feature-edit-window .ui.form .opacity-picker,
  .ui.segment.feature-edit-window .ui.form .marker-options {
    background: #2f3b41;
    border-width: 2px;
    color: white;
    padding: 16px;
    font-family: Arial; }
  .ui.segment.poi-window .ui.form .opacity-picker, .ui.segment.feature-edit-window .ui.form .opacity-picker {
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-radius: 4px; }
    .ui.segment.poi-window .ui.form .opacity-picker .rc-slider, .ui.segment.feature-edit-window .ui.form .opacity-picker .rc-slider {
      margin-bottom: 5px;
      margin-top: 5px;
      max-width: 100px; }
  .ui.segment.poi-window .ui.form .marker-options, .ui.segment.feature-edit-window .ui.form .marker-options {
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    max-width: 300px;
    display: flex;
    flex-wrap: wrap; }
    .ui.segment.poi-window .ui.form .marker-options i, .ui.segment.feature-edit-window .ui.form .marker-options i {
      font-size: 22px;
      margin: 10px;
      cursor: pointer;
      -webkit-transition: 300ms;
      transition: 300ms; }
      .ui.segment.poi-window .ui.form .marker-options i:hover, .ui.segment.feature-edit-window .ui.form .marker-options i:hover {
        -webkit-transform: scale(1.2);
                transform: scale(1.2); }
  .ui.segment.poi-window .ui.form textarea, .ui.segment.feature-edit-window .ui.form textarea {
    border-color: rgba(255, 255, 255, 0.1);
    height: 100px !important; }
  .ui.segment.poi-window .ui.form .color-picker, .ui.segment.feature-edit-window .ui.form .color-picker {
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-radius: 4px; }
    .ui.segment.poi-window .ui.form .color-picker .swatches, .ui.segment.feature-edit-window .ui.form .color-picker .swatches {
      display: flex;
      flex-wrap: wrap;
      max-width: 250px; }
      .ui.segment.poi-window .ui.form .color-picker .swatches .swatch, .ui.segment.feature-edit-window .ui.form .color-picker .swatches .swatch {
        width: 20px;
        height: 20px;
        border-radius: 2px;
        margin: 3px;
        display: inline-block;
        cursor: pointer;
        -webkit-transition: 300ms;
        transition: 300ms;
        display: flex;
        justify-content: center;
        align-items: center; }
        .ui.segment.poi-window .ui.form .color-picker .swatches .swatch:hover, .ui.segment.feature-edit-window .ui.form .color-picker .swatches .swatch:hover {
          -webkit-transform: scale(1.2);
                  transform: scale(1.2); }
  .ui.segment.poi-window .poi-icon, .ui.segment.feature-edit-window .poi-icon {
    position: absolute;
    right: -8px;
    top: -7px;
    cursor: pointer;
    z-index: 11; }
    .ui.segment.poi-window .poi-icon i, .ui.segment.feature-edit-window .poi-icon i {
      font-size: 20px;
      border: 1px solid #ccc;
      border-radius: 50%;
      height: 35px;
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      -webkit-transition: 150ms;
      transition: 150ms;
      opacity: 1;
      height: 25px;
      width: 25px;
      font-size: 14px;
      background: #2e3b41; }
      .ui.segment.poi-window .poi-icon i:hover, .ui.segment.feature-edit-window .poi-icon i:hover {
        opacity: 0.6; }
      .ui.segment.poi-window .poi-icon i:hover, .ui.segment.feature-edit-window .poi-icon i:hover {
        opacity: 1;
        background: #0e1214; }
  .ui.segment.poi-window .button-wrap, .ui.segment.feature-edit-window .button-wrap {
    display: flex;
    justify-content: center; }
  .ui.segment.poi-window .ui.button, .ui.segment.feature-edit-window .ui.button {
    font-family: "Barlow Condensed", sans-serif;
    font-size: 17px;
    font-weight: normal;
    margin-right: 6px;
    background: #192023;
    padding: 10px 14px;
    color: #fff; }
    .ui.segment.poi-window .ui.button:hover, .ui.segment.feature-edit-window .ui.button:hover {
      background: #0e1214; }
  .ui.segment.poi-window .file-upload-field, .ui.segment.feature-edit-window .file-upload-field {
    display: flex;
    align-items: center; }
    .ui.segment.poi-window .file-upload-field .field, .ui.segment.feature-edit-window .file-upload-field .field {
      flex-grow: 1;
      align-items: center;
      margin-bottom: 0px; }

/* Time Slider */
.ui.segment.time-slider-section {
  font-family: "Barlow Condensed", sans-serif;
  background: rgba(46, 59, 65, 0.95);
  z-index: 10;
  position: absolute;
  bottom: 5px;
  width: 350px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  margin: 0px;
  border-radius: 0px;
  border-radius: 3px;
  font-size: 20px;
  box-shadow: -1px -1px 2px -1px #0e1214;
  padding: 0px; }
  .ui.segment.time-slider-section .ui.dropdown.campaign-dropdown {
    width: 100%;
    background: #232d32;
    margin-bottom: 10px;
    padding: 4px;
    text-align: center;
    font-size: 16px;
    text-transform: capitalize; }
    .ui.segment.time-slider-section .ui.dropdown.campaign-dropdown .dropdown.icon {
      margin-left: 0.5em; }
    .ui.segment.time-slider-section .ui.dropdown.campaign-dropdown .menu {
      width: 100%; }
      .ui.segment.time-slider-section .ui.dropdown.campaign-dropdown .menu .item {
        text-align: center; }
        .ui.segment.time-slider-section .ui.dropdown.campaign-dropdown .menu .item .text {
          font-size: 16px;
          text-transform: capitalize; }
  .ui.segment.time-slider-section .main-date-input {
    display: flex;
    justify-content: center;
    width: 100%; }
    .ui.segment.time-slider-section .main-date-input .icon {
      color: #eee;
      opacity: inherit; }
    .ui.segment.time-slider-section .main-date-input .ui.icon.input {
      cursor: pointer; }
    .ui.segment.time-slider-section .main-date-input .calendar.icon {
      max-width: 30px;
      font-size: 18px;
      opacity: 0.5;
      cursor: inherit; }
    .ui.segment.time-slider-section .main-date-input input {
      padding: 0 !important;
      max-width: 128px;
      background: none;
      color: #eee;
      border: none;
      font-family: "Barlow Condensed", sans-serif;
      font-size: 24px;
      cursor: inherit; }
  .ui.segment.time-slider-section .slider-container .slider-wrap {
    position: relative;
    margin: 5px 10px; }
    .ui.segment.time-slider-section .slider-container .slider-wrap .marker-wrap {
      display: none;
      position: absolute;
      bottom: 12px;
      background: transparent;
      color: #fff;
      font-size: 16px;
      flex-direction: column-reverse;
      opacity: 0.5; }
      .ui.segment.time-slider-section .slider-container .slider-wrap .marker-wrap.max {
        right: 10px;
        align-items: flex-end; }
  .ui.segment.time-slider-section .play-buttons {
    margin-bottom: 0px;
    background: transparent;
    font-size: 16px;
    color: #000;
    display: flex;
    justify-content: center; }
    .ui.segment.time-slider-section .play-buttons > div {
      padding: 2px 0px 8px;
      font-size: 12px;
      -webkit-transition: 200ms;
      transition: 200ms;
      width: auto;
      background: black;
      opacity: 0.5;
      color: #fff;
      padding: 3px 10px;
      border-radius: 4px;
      margin: 0px 3px 6px; }
      .ui.segment.time-slider-section .play-buttons > div:hover {
        opacity: 0.8; }
      .ui.segment.time-slider-section .play-buttons > div.play-loop-toggle.active {
        opacity: 0.8; }
  @media screen and (min-width: 620px) {
    .ui.segment.time-slider-section {
      width: 400px; }
      .ui.segment.time-slider-section .slider-container .slider-wrap .marker-wrap {
        bottom: -29px;
        opacity: 0.8;
        display: flex; }
        .ui.segment.time-slider-section .slider-container .slider-wrap .marker-wrap .ts-date .icon {
          width: auto;
          max-width: 70px;
          color: #eee;
          opacity: inherit; }
        .ui.segment.time-slider-section .slider-container .slider-wrap .marker-wrap .ts-date input {
          padding: 0;
          max-width: 200px;
          background: none;
          color: #eee;
          border: none;
          font-family: "Barlow Condensed", sans-serif;
          cursor: pointer; } }

.play-buttons {
  display: flex;
  margin-bottom: 10px;
  flex-direction: row;
  background: #192023;
  border-radius: 3px; }
  .play-buttons > div {
    width: 25%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center; }
    .play-buttons > div:hover {
      cursor: pointer;
      background: #1b1c1d; }

/* Users table column style */
.check.icon {
  color: #2d8e3f; }

.center-text {
  text-align: center; }

.capitalize-text {
  text-transform: capitalize; }

/* POIs list table */
.pois-container .ReactTable .rt-td {
  text-overflow: ellipsis;
  overflow: hidden; }

.pois-container .poi-edit-buttons {
  display: flex;
  flex-wrap: wrap; }
  .pois-container .poi-edit-buttons button {
    width: 48%;
    margin: 1%; }

/* Custom data table */
img.custom-data-icon {
  width: auto;
  height: 50px; }

.line-distance-container {
  position: absolute;
  top: 150px;
  border-radius: 2px;
  left: 10px;
  z-index: 10;
  color: #fff;
  box-shadow: 1px 1px 2px -1px #0e1214;
  background: #2e3b41;
  font-family: Arial;
  min-width: 250px; }
  .line-distance-container .header-icons {
    padding: 10px;
    border-bottom: 1px solid; }
    .line-distance-container .header-icons button {
      background: transparent;
      border: 1px solid;
      border-radius: 50px;
      color: #fff;
      padding: 8px 10px;
      display: flex;
      align-items: center;
      letter-spacing: 0.4px; }
      .line-distance-container .header-icons button:focus {
        outline: none; }
      .line-distance-container .header-icons button:hover {
        opacity: 0.6; }
      .line-distance-container .header-icons button i {
        color: #6EA7E7;
        font-size: 16px;
        margin-right: 6px; }
    .line-distance-container .header-icons .fa-times {
      font-size: 20px;
      border: 1px solid #ccc;
      border-radius: 50%;
      height: 35px;
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      -webkit-transition: 150ms;
      transition: 150ms;
      opacity: 1;
      float: right; }
      .line-distance-container .header-icons .fa-times:hover {
        opacity: 0.6; }
  .line-distance-container span, .line-distance-container label {
    display: block;
    margin-left: 15px;
    margin-bottom: 15px;
    font-size: 22px; }
  .line-distance-container label {
    font-size: 16px;
    margin-top: 15px; }

.blogs-modal {
  font-family: "Barlow Condensed", sans-serif;
  width: 92vw;
  max-width: 1050px; }
  .blogs-modal .modal-content {
    min-height: 80vh; }
  .blogs-modal .blogs-custom-header {
    width: 100%;
    text-align: center;
    font-size: 38px;
    position: relative;
    padding: 10px 0px;
    margin-bottom: 10px;
    background: #2e3b41;
    color: #fff; }
    .blogs-modal .blogs-custom-header .fa-times {
      font-size: 20px;
      border: 1px solid #ccc;
      border-radius: 50%;
      height: 35px;
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      -webkit-transition: 150ms;
      transition: 150ms;
      opacity: 1;
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 18px;
      cursor: pointer;
      transition: 150ms;
      color: #fff; }
      .blogs-modal .blogs-custom-header .fa-times:hover {
        opacity: 0.6; }
      .blogs-modal .blogs-custom-header .fa-times:hover {
        opacity: 0.7; }
  .blogs-modal .modal-body .featured-carousel {
    max-width: 1000px;
    margin: 0px auto 40px;
    border-radius: 4px; }
    .blogs-modal .modal-body .featured-carousel .slider-container {
      border-radius: 4px; }
    .blogs-modal .modal-body .featured-carousel .blog-item {
      height: 100%;
      border-radius: 0px; }
      .blogs-modal .modal-body .featured-carousel .blog-item .featured-title {
        background: #212b2f;
        color: #fff;
        font-size: 21px;
        font-family: "Barlow Condensed", sans-serif;
        text-align: center;
        padding: 10px 14px;
        margin-bottom: 0px;
        text-transform: uppercase; }
      .blogs-modal .modal-body .featured-carousel .blog-item .blog-img {
        height: calc(100% - 45px);
        background-size: contain;
        background-repeat: no-repeat;
        background-color: #2e3b41; }
    .blogs-modal .modal-body .featured-carousel .slider-dots {
      bottom: -30px; }
      .blogs-modal .modal-body .featured-carousel .slider-dots button {
        border: 1px solid #2e3b41; }
        .blogs-modal .modal-body .featured-carousel .slider-dots button[disabled] {
          background: #2e3b41; }
    .blogs-modal .modal-body .featured-carousel .slider-arrow-buttons button i {
      font-size: 40px; }
  .blogs-modal .modal-body .normal-blogs {
    padding-top: 30px; }
    .blogs-modal .modal-body .normal-blogs .search-and-filter {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center; }
      .blogs-modal .modal-body .normal-blogs .search-and-filter .blog-select, .blogs-modal .modal-body .normal-blogs .search-and-filter .blog-search-input {
        min-width: 200px;
        max-width: 200px;
        font-size: 18px;
        margin: 5px 5px;
        color: #2e3b41; }
      .blogs-modal .modal-body .normal-blogs .search-and-filter .blog-select > div {
        border: 1px solid #2e3b41;
        border-radius: 4px;
        box-shadow: none; }
      .blogs-modal .modal-body .normal-blogs .search-and-filter .blog-select .css-151xaom-placeholder {
        color: rgba(46, 59, 65, 0.6);
        font-weight: bold; }
      .blogs-modal .modal-body .normal-blogs .search-and-filter .blog-search-input {
        height: 41px; }
        .blogs-modal .modal-body .normal-blogs .search-and-filter .blog-search-input > input {
          font-family: "Barlow Condensed", sans-serif;
          color: #2e3b41;
          border: 1px solid #2e3b41;
          border-radius: 4px; }
          .blogs-modal .modal-body .normal-blogs .search-and-filter .blog-search-input > input::-webkit-input-placeholder {
            color: rgba(46, 59, 65, 0.6); }
          .blogs-modal .modal-body .normal-blogs .search-and-filter .blog-search-input > input::-moz-placeholder {
            color: rgba(46, 59, 65, 0.6); }
          .blogs-modal .modal-body .normal-blogs .search-and-filter .blog-search-input > input::-ms-input-placeholder {
            color: rgba(46, 59, 65, 0.6); }
          .blogs-modal .modal-body .normal-blogs .search-and-filter .blog-search-input > input::placeholder {
            color: rgba(46, 59, 65, 0.6); }
    .blogs-modal .modal-body .normal-blogs .normal-blogs-wrap {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, -webkit-max-content));
      grid-template-columns: repeat(auto-fit, minmax(300px, max-content));
      grid-gap: 16px;
      justify-content: center;
      padding: 15px; }
  .blogs-modal .modal-body .blog-item {
    height: 300px;
    position: relative;
    box-shadow: 0px 0px 4px -1px;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer; }
    .blogs-modal .modal-body .blog-item:last-child {
      margin-right: 0px; }
    .blogs-modal .modal-body .blog-item .blog-img {
      height: 100%;
      background-size: cover;
      background-position: center; }
      .blogs-modal .modal-body .blog-item .blog-img h1 {
        background: rgba(14, 18, 20, 0.8);
        color: #fff;
        font-size: 21px;
        font-family: "Barlow Condensed", sans-serif;
        text-align: center;
        padding: 10px 14px;
        text-transform: uppercase; }
    .blogs-modal .modal-body .blog-item .blog-description {
      position: absolute;
      bottom: -5px;
      left: 0px;
      right: 0px;
      opacity: 0;
      background: rgba(25, 32, 35, 0.85);
      font-size: 18px;
      padding: 10px 15px;
      -webkit-transition: 300ms;
      transition: 300ms;
      color: #fff; }
      .blogs-modal .modal-body .blog-item .blog-description a {
        margin-left: 10px;
        color: #ff625e;
        float: right;
        -webkit-transition: 200ms;
        transition: 200ms; }
        .blogs-modal .modal-body .blog-item .blog-description a:hover {
          color: #ff1712; }
    .blogs-modal .modal-body .blog-item:hover .blog-description {
      opacity: 1;
      bottom: 0px; }
  @media screen and (max-width: 767px) {
    .blogs-modal {
      width: auto; } }

.basemaps-modal {
  margin: auto;
  max-width: 380px;
  color: #fff; }
  .basemaps-modal .basemaps-header {
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    padding: 0 0 10px 0px;
    margin-bottom: 10px;
    font-family: "Barlow Condensed", sans-serif; }
    .basemaps-modal .basemaps-header .fa-times {
      font-size: 20px;
      border: 1px solid #ccc;
      border-radius: 50%;
      height: 35px;
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      -webkit-transition: 150ms;
      transition: 150ms;
      opacity: 1;
      position: absolute;
      top: 5px;
      right: 0px;
      font-size: 18px; }
      .basemaps-modal .basemaps-header .fa-times:hover {
        opacity: 0.6; }
  .basemaps-modal .modal-content {
    padding: 12px;
    background: #2e3b41;
    color: #fff; }
    .basemaps-modal .modal-content .ui.toggle.checkbox input ~ label, .basemaps-modal .modal-content .ui.toggle.checkbox input:checked ~ label {
      margin-left: 3%;
      color: #fff !important;
      font-weight: bold;
      font-size: 13px; }
    .basemaps-modal .modal-content .basemaps-container {
      display: flex;
      flex-wrap: wrap; }
      .basemaps-modal .modal-content .basemaps-container > div {
        position: relative;
        width: 44%;
        height: 100px;
        background-size: cover;
        margin: 3%;
        border-radius: 12px;
        overflow: hidden;
        border: 1px solid #111;
        cursor: pointer; }
        .basemaps-modal .modal-content .basemaps-container > div.active {
          border-width: 3px;
          border-color: #841105; }
        .basemaps-modal .modal-content .basemaps-container > div label {
          padding: 6px 0;
          width: 100%;
          font-size: 13px;
          text-align: center;
          font-weight: bold;
          border-bottom: 1px solid #555;
          background: rgba(0, 0, 0, 0.3); }

#target-photos-button {
  position: absolute;
  bottom: 15px;
  right: 66px;
  width: 35px;
  height: 45px; }
  @media screen and (max-width: 768px) {
    #target-photos-button {
      display: none; } }

#target-basemaps {
  position: absolute;
  top: 80px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 380px;
  height: 80vh; }

#target-units {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100vw;
  height: 100vh; }

#react-joyride-step-1, #react-joyride-step-1 .__floater {
  z-index: 1051 !important; }

@media screen and (max-width: 768px) {
  .ui.segment.donation-prompt {
    top: 102px; }
  .fa-question-circle {
    top: 12px;
    right: auto;
    left: 70px;
    bottom: auto; }
  .ui.segment.tutorial-prompt {
    bottom: 139px; }
  #target-photos-button {
    bottom: 154px; }
  .side-bar {
    top: 44px;
    bottom: auto;
    left: 0;
    right: 0;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px 10px 6px;
    background: #232d32;
    z-index: 11; }
    .side-bar .icon-wrap {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      margin: 0; }
      .side-bar .icon-wrap .icon-label {
        display: block;
        color: rgba(255, 255, 255, 0.9);
        font-family: "Barlow Condensed", sans-serif;
        font-size: 16px;
        letter-spacing: 0.5px;
        text-transform: capitalize; }
    .side-bar i {
      margin: 4px 0px; }
    .side-bar .drawer-panel {
      position: fixed;
      top: 110px;
      right: -100%;
      width: 100%;
      max-width: none; }
      .side-bar .drawer-panel.active {
        right: 0;
        width: 100%;
        max-width: none; }
      .side-bar .drawer-panel .ui.segment.filters-box, .side-bar .drawer-panel .ui.segment.map-settings {
        max-height: 100%;
        min-width: 250px; }
    .side-bar .war-diaries-wrap .ui.vertical.sidebar {
      max-height: calc(100vh - 144px - 44px);
      max-width: 75vw; }
    .side-bar .fa-wrench {
      display: none; }
    .side-bar .story-map-link {
      display: none; }
    .side-bar .tools-placeholders {
      display: none; }
  .main-nav.bg-dark {
    max-width: 100%;
    width: 100%;
    border-radius: 0px;
    margin: 0px;
    justify-content: flex-end; }
  .navbar-nav {
    align-items: flex-end; }
  .navbar-dark .navbar-toggler {
    margin: 5px; }
  .main-nav .navbar-brand {
    height: 57px;
    width: 55px; }
  .main-nav .nav-item {
    margin: 8px 12px; }
  .ui.inverted.menu {
    top: 0px;
    left: 0px;
    right: 0px;
    margin: 0px;
    border-radius: 0px; }
  .ui.inverted.menu a {
    padding: 10px; }
  .ui.button.war-diaries-toggle {
    top: 40px;
    margin-top: 1rem;
    width: 80px;
    left: 10px; }
  .ui.segment.time-slider-section {
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: 0px;
    width: auto;
    border-radius: 0px;
    padding: 0 0 8px;
    height: 144px;
    -webkit-transform: none;
            transform: none; }
  .time-slider-section p {
    display: none; }
  .ui.segment.time-slider-section .slider-wrap {
    display: flex; }
    .ui.segment.time-slider-section .slider-wrap div {
      flex-grow: 1; }
    .ui.segment.time-slider-section .slider-wrap .rc-slider {
      margin-bottom: 0px; }
    .ui.segment.time-slider-section .slider-wrap .date {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 160px; }
  .ui.segment.time-slider-section .play-buttons > div {
    padding: 6px 16px;
    font-size: 14px;
    margin: 4px 5px 0; }
    .ui.segment.time-slider-section .play-buttons > div.play-loop-toggle {
      display: none; }
  .mapboxgl-ctrl-bottom-right {
    bottom: 114px;
    right: 0px; }
  .mapboxgl-ctrl-bottom-left {
    bottom: 144px; } }

@media screen and (max-width: 767px) {
  .fa-question-circle {
    right: 75px;
    left: auto; } }

@media screen and (min-width: 1020px) {
  .cc {
    display: block !important;
    z-index: 100;
    background: #2e3b41;
    font-family: "Barlow Condensed", sans-serif;
    color: #aaa;
    font-size: 14px;
    position: absolute;
    bottom: 0;
    left: 0;
    min-width: 240px;
    text-align: center;
    padding: 10px;
    border-top-right-radius: 3px;
    max-height: 40px;
    max-width: 305px;
    -webkit-transition: 400ms;
    transition: 400ms;
    overflow: hidden;
    box-shadow: 0px 0px 4px -1px #0e1214; }
    .cc a {
      color: #fff; }
      .cc a:hover {
        color: #ccc; }
    .cc:hover {
      max-height: 200px; } }

/* overwriting react-table cells "overflow" property so our dropdowns appear outside of the cells */
/* custom data-list page only */
.custom-data-list-page .ReactTable .rt-th, .ReactTable .rt-td {
  overflow: visible; }

.blogs-table .rt-td {
  white-space: pre-wrap; }

.css-1pcexqc-container {
  z-index: 5; }

.marker {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer; }
  .marker .mapboxgl-popup-content {
    background: transparent;
    box-shadow: none; }
    .marker .mapboxgl-popup-content .temp-marker {
      width: 25px;
      height: 25px; }
    .marker .mapboxgl-popup-content i {
      font-size: 22px;
      color: #2e3b41; }

.slider-dots {
  text-align: center;
  position: absolute;
  bottom: 5px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }
  .slider-dots button {
    width: 18px;
    height: 18px;
    background: transparent;
    border: 1px solid #ccc;
    border-radius: 50%;
    margin: 3px; }
    .slider-dots button[disabled] {
      background: #fff; }
    .slider-dots button:focus {
      outline: none; }

.slider-arrow-buttons {
  position: absolute;
  top: 50%;
  width: 100%;
  height: 0px;
  display: flex;
  justify-content: space-between; }
  .slider-arrow-buttons button {
    background: none;
    border: none; }
    .slider-arrow-buttons button:focus {
      outline: none; }
    .slider-arrow-buttons button[disabled] {
      opacity: 0.2; }
    .slider-arrow-buttons button i {
      font-size: 30px;
      text-shadow: 1px 1px #000;
      color: #fff; }

body {
  background: rgb(247, 244, 240);
}

.login {
  height: 100vh;
  width: 100vw;
  background: rgb(247, 244, 240);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.dashboard-container {
  min-height: 100vh;
}

.dashboard-nav {
  margin: 10px;
  border-radius: 4px;
  color: #fff;
  font-family: "Lato";
  background-color: #1b1c1d !important;
  padding: 0 5px 0 0;
}

@media screen and (min-width: 720px) {
  /* .dashboard-nav {
    max-width: 90vw;
  } */
}
@media screen and (min-width: 900px) {
  /* .dashboard-nav {
    max-width: 80vw;
  } */
}
@media screen and (min-width: 1200px) {
  /* .dashboard-nav {
    max-width: 66.66vw;
  } */
}

.dashboard-nav .navbar-brand {
  font-family: "Special Elite", cursive;
  font-size: 26px;
  background: #333;
  padding: 9px 12px 1px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.dashboard-nav .nav-item {
  margin: 4px 8px;
  font-size: 16px;
  white-space: nowrap;
  cursor: pointer;
}

.dashboard-nav .nav-item.active {
  border-bottom: 3px solid #00b5ad;
}
.dashboard-nav .nav-item i {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
  font-size: 30px;
  border-radius: 50%;
  background: #333;
  color: #fff;
}

.dashboard-nav a {
  color: #fff;
}
.dashboard-nav a:hover {
  text-decoration: none;
}

.features-container {
  /* width : 94vw; */
  width: 100%;
  margin: 30px auto;
}
.dashboard-date {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.dashboard-date i {
  margin: 0px 15px;
  font-size: 14px;
  color: #ccc;
  cursor: pointer;
  -webkit-transition: 200ms;
  transition: 200ms;
  border: 1px solid;
  padding: 6px;
  border-radius: 4px;
}
.dashboard-date i:hover {
  color: #333;
}
.dashboard-nav .nav-item .ui.large.basic.label {
  margin-top: 10px;
  font-family: lato;
}
.feature-table {
  margin: 20px 0;
  font-family: lato;
  min-width: 100%;
  float: left;
  background-color: #ffffff;
}

.map-edit {
  /* background : #ddd; */
  padding-bottom: 10px;
  float: left;
  width: 100%;
}
.map-edit .ui.segment.edit-pane {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  max-width: 90vw;
  min-height: 500px;
}
.map-edit .edit-pane h2 {
  width: 100%;
  text-align: center;
}
.map-edit .edit-pane .props {
  padding-right: 20px;
  width: 40%;
  min-width: 400px;
}
.map-edit .edit-pane .map-wrap {
  flex-grow: 1;
  margin: 24px 20px;
}
.map-edit .edit-pane #map {
  min-height: 400px;
  width: 100%;
  position: relative;
  margin-top: 10px;
}
.buttonWrap {
  width: 100%;
  margin: 10px 0px;
  text-align: center;
}

.hide-section {
  display: none;
}

.search-wrap .date-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.search-wrap .date-wrap .date-label-text {
  font-size: 14px;
  margin: 0;
}
.search-wrap .date-wrap .date-text {
  font-size: 20px;
}
.filter-wrap {
  display: flex;
  margin-left: 20px;
}
.filter-wrap .single-filter {
  width: 150px;
  margin-right: 10px;
}
.filter-wrap .select-label {
  margin: 0;
}

h5.custom-data-error {
  font-size: 1rem;
  line-height: 2rem;
  background-color: #cc5959;
  padding: 5px 10px;
  margin-left: 10px;
  border-radius: 6px;
  color: white;
  font-weight: 700;
}

@media screen and (max-width: 900px) {
  .map-edit .ui.segment.edit-pane {
    flex-direction: column;
  }
}

