@import '_variables_mixins.scss';

$footerHeight : 52px;

.main-diary {
  margin: 10px;
  text-align: left;
  padding: 10px;
  /*background: rgba(255,255,255,0.8);
  border-radius: 4px;
  */
  color: #000;
  padding: 8px;
}

.main-diary p {
    line-height: 1.55em;
    color :  #2e3b41;
    font-family : arial;
}

.main-diary iframe {
    height: auto;
    width: 100%;
}

.diary-internal .c3 {
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 9pt;
    font-family: "Arial";
    font-style: normal
}

.diary-internal .c0 {
    padding-top: 0pt;
    padding-bottom: 5pt;

    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt
}

.diary-internal .c1 {
    padding-top: 0pt;
    padding-bottom: 5pt;

    orphans: 2;
    widows: 2;
    text-align: left
}

.diary-internal .c15 {
    padding-top: 0pt;
    padding-bottom: 0pt;

    orphans: 2;
    widows: 2;
    text-align: left
}

.diary-internal .c9 {
    padding-top: 0pt;
    padding-bottom: 8pt;

    orphans: 2;
    widows: 2;
    text-align: left
}

.diary-internal .c21 {
    padding-top: 0pt;
    padding-bottom: 8pt;

    orphans: 2;
    widows: 2;
    text-align: left
}

.diary-internal .c16 {
    -webkit-text-decoration-skip: none;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-style: normal
}

.diary-internal .c23 {

    font-weight: 700;
    font-size: 8pt;
    font-family: "Arial"
}

.diary-internal .c17 {

    font-weight: 700;
    font-size: 11pt;
    font-family: "Arial"
}

.diary-internal .c30 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    text-align: left
}

.diary-internal .c20 {

    font-weight: 700;
    font-size: 11pt;
    font-family: "Calibri"
}

.diary-internal .c24 {

    font-weight: 700;
    font-size: 12pt;
    font-family: "Times New Roman"
}

.diary-internal .c29 {
    margin-left: -5.4pt;
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto
}

.diary-internal .c11 {

    font-weight: 400;
    font-size: 9pt;
    font-family: "Arial"
}

.diary-internal .c2 {
    font-size: 11pt;
    font-family: "Arial";

    font-weight: 400
}

.diary-internal .c4 {
    text-decoration: none;
    vertical-align: baseline;
    font-style: normal
}

.diary-internal .c25 {
    font-weight: 400;
    font-size: 11pt;
    font-family: "Arial"
}

.diary-internal .c18 {
    -webkit-text-decoration-skip: none;
    text-decoration: underline;
    text-decoration-skip-ink: none
}

.diary-internal .c13 {
    text-decoration: none;
    vertical-align: baseline;
    font-style: italic
}

.diary-internal .c7 {
    -webkit-text-decoration-skip: none;
    text-decoration: line-through;
    text-decoration-skip-ink: none
}

.diary-internal .c27 {
    background-color: #ffffff;
    max-width: 468pt;
    padding: 72pt 72pt 72pt 72pt
}

.diary-internal .c19 {
    vertical-align: baseline;
    font-style: normal
}

.diary-internal .c26 {
    font-weight: 400;
    font-family: "Arial"
}

.diary-internal .c10 {
    height: 0pt
}

.diary-internal .c14 {
    font-style: italic
}

.diary-internal .c22 {
    font-style: normal
}

.diary-internal .c12 {
    height: 11pt
}

.diary-internal .c28 {
    background-color: #ffff00
}

.diary-internal .title {
    padding-top: 24pt;

    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    font-family: "Calibri";

    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.diary-internal .subtitle {
    padding-top: 18pt;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: "Georgia";

    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}

.diary-list {
    max-width: 300px;
    background: rgba(46, 59, 65, 0.9) !important;
    .ui.header {
        margin-top : 25px;
        font-size : 24px;
        font-family : $mainFont;
        i {
            @include close-button-mixin;
            position : absolute;
            left : 24px;
            top : 23px;
            font-size : 22px;
            text-shadow : none;
            color : $mainColor;
        }
    }
}

.diary-arrows.play-buttons  {
    background: transparent;
    display: flex;
    justify-content: center;
    margin: 0;
    padding-top : 10px;
    > div {
        margin: 0px 5px;
        border-radius: 4px;
        color : #fff;
        background: #192023;
        padding : 5px 0px;
        width : 70px;
        i {
            font-size : 20px;
            margin : 0px;
            //padding-bottom : 10px;
            padding : 0px;
        }
        &:hover {
            background: #192023;
            color : #000;
        }
    }
}

.ui.visible.push.sidebar.war-diaries {
    overflow : hidden !important;
    display : flex;
    //border-bottom : none;
    border : none;
    border-right : 1px solid;
}
.war-diaries .ui.header {
    background : #2e3b41;
    color : #fff;
    font-family: $mainFont;
    font-size : 24px;
    &.main-war-diaries-header {
        margin-bottom : 0px;
        i.fa-times {
            @include close-button-mixin;
            position : absolute;
            top : 13px;
            left : 13px;
            font-size : 16px;
            height : 30px;
            width : 30px;
        }
        .text-size-controls {
            position : absolute;
            top : 10px;
            right : 10px;
            i {
               margin : 4px;
               font-size : 18px;
            }
        }
    }
    &.date {
        /* margin-left : -40px; */
        border-radius : 3px;
        padding : 10px;
        text-transform : uppercase;
        span {
            position : absolute;
            left : -30px;
            color : green;
        }
    }
    h1 {
        font-family: $mainFont;
        font-size : 22px;
        padding : 14px 8px;
        width : 100%;

        &.source {
            font-size: 18px;
            padding: 0;
        }
    }
    span {
        display : inline-block;
    }
}

.war-diary-buttons {
    height : $footerHeight;
    background : $mainColor;
    text-align: center;
    padding: 6px;
    .ui.button {
        font-family : 'Barlow Condensed', sans-serif;
        font-size : 16px;
        background: #192023;
    }
}

.ui.vertical.sidebar {
    position : relative;
    /*
    max-height : 100vh;
    min-height : 100vh;
    overflow : auto;
    */
    .ui.toggle.checkbox input~label, .ui.toggle.checkbox input:checked~label {
      color: #fff !important;
      font-family : $mainFont;
      letter-spacing : 0.6px;
      font-size : 18px;
      margin-bottom : 15px;
      text-align : left;
    }
    .war-diary-menu {
        display : flex;
        list-style : none;
        width : 100%;
        padding : 0;
        margin : 0;
        li {
            border : 1px solid darken($mainColor, 10%);
            border-right : none;
            flex-grow : 1;
            padding : 3px 0px;
            font-size : 18px;
            cursor : pointer;
            transition : 200ms;
            &.active {
                background : darken($mainColor, 10%);
            }
            &:hover:not(.active) {
                background : darken($mainColor, 5%);
            }
        }
    }
    .unit-info {
        font-family : $mainFont;
        font-size : 18px;
        padding-top : 16px;
        .unit-data-list {
            padding : 0px;
            margin : 30px 0 30px 20px;
            text-align : left;
            list-style : none;
            font-family : $mainFont;
            label {
                font-weight : bold;
                opacity: 0.6;
                text-transform: uppercase;
                font-size: 17px;
                min-width: 100px
            }
        }
        .wiki-content, .diary-not-found-content {
            text-align : left;
            line-height: 1.55em;
            color: #2e3b41;
            font-family: arial;
            margin : 15px;
            h1 {
                font-size : 1.5em;
                margin : 8px 0px;
                font-family : $mainFont;
                color : #000;
                font-weight : bold;
            }
        }
        a {
            font-size : 18px;
            font-family : $mainFont;
            color : #000;
            margin-bottom : 15px;
            transition : 300ms;
            font-weight : bold;
            i {
                font-size : 16px;
                text-shadow : none;
                color : inherit;
                opacity : 1;
                margin-left : 3px;
                transition : none;
            }
            &:hover {
                text-decoration : none;
                color : $accentRed;
            }
        }
    }
}
.diaries {
    overflow : auto;
    border-left : 1px solid #ccc;
}


@media screen and (max-width : 768px) {
    .ui.visible.push.sidebar.war-diaries {
        width: 100%;
        border : none;
        border-top : 1px solid darken($mainColor, 5%);
    }
    .war-diary-buttons {
        width: 100%;
    }
}
